import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TitleBar from "../component/title/TitleBar";
import { FiYoutube } from "react-icons/fi";
import { services } from "../services";
import axios from "axios";

// Import Images
import image1 from "../assets/gallery/pics-17.jpg";
import image2 from "../assets/gallery/pics-21.jpg";
import image3 from "../assets/gallery/pics-37.jpg";
import image4 from "../assets/gallery/pics-44.jpg";
import image5 from "../assets/gallery/pics-52.jpg";
import image6 from "../assets/gallery/pics-58.jpg";
import image7 from "../assets/gallery/pics-6.jpg";
import image8 from "../assets/gallery/pics-61.jpg";
import image9 from "../assets/gallery/pics-69.jpg";
import image10 from "../assets/gallery/pics-77.jpg";
import image11 from "../assets/gallery/pics-84.jpg";
import image12 from "../assets/gallery/pics-85.jpg";

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);

  //GAllery API call function
  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const response = await axios.get(services.getGallery);
        setGalleryData(response?.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchGallery();
  }, []);

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
  ];

  // const channelId = 'YOUR_YOUTUBE_CHANNEL_ID'; // Replace with your YouTube channel ID

  // const handleSubscribe = () => {
  //     window.open(`https://www.youtube.com/channel/${channelId}/subscribe`);
  // };

  return (
    <>
      <TitleBar
        pagetitle="Swatee Health Solutions Gallery"
        breadcrumbs={
          <>
            <Link to="/">Home</Link> &gt;
            <Link to=""> Gallery</Link>
          </>
        }
      />
      <div className="container mt-12 px-4 pb-8 flex sm:flex-col lg:flex-row">
        <div className="basis-[50%] p-2 mb-8 mr-8 sm:mr-0 sm:p-8">
          <h2 className="pb-6">
            Susbscribe to Swatee Health Solutions{" "}
            <span className="text-[#c4302b]">YouTube Channel.</span>
          </h2>
          {/* <a className='bg-[#c4302b] px-6 py-2 text-[white] rounded-full' href={`https://www.youtube.com/channel/${channelId}`} onClick={handleSubscribe}>
                        <FiYoutube size={64} color="#c4302b" /> 
                        Subscribe
                    </a> */}
          <a
            className="bg-[#c4302b] px-6 py-2 text-[white] rounded-full"
            href="https://www.youtube.com/@SwateeHealthSolutions"
            target="_blank "
          >
            {/* <FiYoutube size={64} color="#c4302b" /> */}
            View Channel
          </a>
        </div>
        <div className="basis-[50%] mb-8 ml-8 sm:ml-0 sm:p-8">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/c8rI1YP2YXw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* API Gallery started */}
      <div className="max-w-[1200px] mx-auto py-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4">
          {galleryData.map((image, index) => (
            <img
              key={index}
              src={`${services.gallery_base_URL}${image.image_name}`}
              className="w-full h-[300px] object-cover"
            />
          ))}
        </div>
      </div>

      {/* Gallery started */}
      <div className="max-w-[1200px] mx-auto py-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              className="w-full h-full object-cover"
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Gallery;
