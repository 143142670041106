import React from 'react'
import { Link } from 'react-router-dom';
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'
import ContactForm from '../component/forms/ContactForm';

const JyotishPeeth = () => {
    return (
        <>
            <TitleBar pagetitle="Swatee Jyotish Peeth" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Swatee Jyotish Peeth</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='py-6 text-lg'>SWATEE JYOTISH</h2>
                    <p className='text-justify text-sm py-2 text-[#4d4d4d]'>
                        Swatee Jyotish Peeth is our Jyotish related platform.
                    </p>
                    <p className='text-justify text-sm py-2 text-[#4d4d4d]'>
                        We may analyzing in the following fields –.
                    </p>
                    <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">

                        <li className='p-1'>
                            Medical Astrology
                        </li>

                        <li className='p-1'>
                            Career
                        </li>

                        <li className='p-1'>
                            Marriage
                        </li>

                        <li className='p-1'>
                            Child
                        </li>

                        <li className='p-1'>
                            Super natural
                        </li>

                    </ol >
                    <h3 className='py-6 text-lg'>For any quiry Call Us: <br /><span className='text-primary text-xl'><a href="tel:7055520015">(+91) 70555 20015</a></span></h3>
                    <h3 className='py-6 text-lg'>Visit our website for more details: <br /><span className='text-primary text-xl'><a href="http://swateejyotishpeeth.com/" target='_blank'>www.swateejyotishpeeth.com</a></span></h3>
                </div>
                <div className='basis-[25%] h-[450px] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>

                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div >

            {/* Contact Form  */}
            < div className='container px-4 py-12 flex items-center sm:flex-col lg:flex-row ' >
                <div className='basis-[100%] shadow-md p-12 m-[10px]'>
                    <h3 className='py-2 border-l-2 border-primary mb-8'>
                        <span className='pl-2'>Submit Your Query</span>
                    </h3>
                    <h3 className='py-6 text-lg'>Use below form to reach us:</h3>

                    <div className='basis-[100%]'>

                        <ContactForm />

                    </div>
                </div>
            </div >
        </>
    )
}

export default JyotishPeeth
