import React from 'react'
import ProductCard from '../products/ProductCard'
import HomeProducts from '../products/HomeProducts'

const FeaturedProduct = () => {

    return (
        <div className='w-full'>
            <div className='max-w-[1200px] mx-auto py-12'>
                <h2 className='text-[36px]'>Our Leading Products</h2>
            </div>
            <div className='max-w-[1200px] mx-auto grid 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 xl:grid-col-4 gap-6 pb-20'>
                <HomeProducts />
            </div>
        </div>
    )
}

export default FeaturedProduct
