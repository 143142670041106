import React from 'react'
import { Link } from 'react-router-dom';
// import logo from '../assets/logo.jpeg'
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'

const PromotionPolicies = () => {
    return (
        <>
            <TitleBar pagetitle="Promotion Policies" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Promotion Policies</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='py-6 text-lg'>प्रमोशन सम्बन्धी नियम –</h2>
                    <p className='text-justify text-sm py-6'>
                        जीवन का कोई भी क्षेत्र हो, हर कोई उन्नति की चाह रखता हैं क्योंकि उन्नति ही हमें, हमारे सपनों को और हमें उर्जावान बनाये रखती हैं अतः जीवन में उन्नति अनिवार्य हैं और आप इस व्यवसाय में निम्नप्रकार से उन्नतिशील हो सकते है अर्थात प्रमोशन पा सकते हैं –
                    </p>
                    <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">

                        <li className='p-1'>
                        प्रमोशन पाने का एक मात्र आधार है आप के द्वारा आपके डाउन में कितने लेग लेवल्स पूर्ण हुए हैं।
                        </li>

                        <li className='p-1'>
                        जब भी आप एक रैंक को पूरा करते हैं तो आपका प्रमोशन स्वचालित तरीक़े से ही ऊपर के रैंक पर हो जाता है, इसके लिए कोई समय सीमा निर्धारित नहीं है।
                        </li>

                        <li className='p-1'>
                        DS का प्रमोशन पर प्रोमोशन प्लान चार्ट के अनुसार होता है।       
                        </li>

                        <p className='p-1'>
                        उदाहरण- यदि आप वर्तमान में सिल्वर स्टार हैं तो जब आपके नेटवर्क ग्रुप में सदस्यों की संख्या नियमानुसार कम से कम चार हो जाएगी तो आप का प्रमोशन स्वत् ही गोल्ड स्टार पर हो जाता है ।
                        </p>

                    </ol >
                </div>
                <div className='basis-[25%] h-[450px] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>

                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div>
        </>
    )
}

export default PromotionPolicies