import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import TitleBar from '../component/title/TitleBar'
import { BsCheckCircleFill } from 'react-icons/bs'
import axios from 'axios';
import { services } from '../services';

const ThankYou = () => {
    const [orderToShow, setOrderToShow] = useState({});

    useEffect(() => {
        axios.get(services.getOrder).then(res => {
            console.log(res.data.data);
            setOrderToShow(res?.data.data);
        }).catch(e => {
            console.log(e);
        })
    }, []);
    return (
        <>
            {/* <TitleBar pagetitle="About Swatee Health Solutions" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> About</Link>
                </>}
            /> */}
            <div className='max-w-[1200px] mx-auto px-4 py-12 my-12'>
                <h2 className='text-center text-2xl'>Your order has been placed successfully</h2>
                <BsCheckCircleFill className='mx-auto text-[60px] text-primary my-6' />
                <h3 className='text-center py-2'>Thanku for your purchase!</h3>
                <p className='text-center py-2'>You will receive an order confirmaion email with details of your order.</p>
                <p className='text-center '>Your order ID is: {orderToShow[0]?.id}</p>
                <div className='flex justify-center'>
                    <Link to="/shop">
                        <button className="btn bg-white text-primary border-b border-primary mt-0">
                            Continue Shopping
                        </button>
                    </Link>
                    <Link to="/myaccount">
                        <button className="btn ml-6 bg-white text-primary border-b border-primary mt-0">
                            My Account
                        </button>
                    </Link>
                </div>
            </div>

        </>
    )
}

export default ThankYou
