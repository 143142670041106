import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import productImg from '../../assets/aloe-vera.jpeg';
import LoadingSpinner from '../actions/LoadingSpinner';
import swal from 'sweetalert';
import { ShoppingCartContext } from '../context/shoppingCartContext';
import { checkProductExistIntoState, redirectToPage } from '../Utility';
import { useDispatch, useSelector } from 'react-redux';
import { cartDataAdd } from '../../actions';
import { services } from '../../services';

const image_base_URL = 'https://admin.swatee.in/uploads/product_images/';

const HomeProducts = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  // const [cartData, setCartData] = useState(null);
  //const [cartProducts, setCartProducts] = useState([]);

  const data = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  // // Loader
  useEffect(() => {
    // simulate loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  // Fetch product data
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(services.getProduct);
        setProducts(response.data.data.slice(0, 4));
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
  }, []);

  // Add to Cart api
  const handleAddToCart = (product) => {
    let previousProductInState = data.cart;
    console.log(previousProductInState);
    let alredyExist = previousProductInState.filter((item) => {
      if (product.id === item.id) {
        return product;
      }
    });
    if (alredyExist.length > 0) {
      swal({
        TitleBar: "Oops!",
        text: "Product already Available in cart",
        icon: "warning",
      });
    } else {
      //let updatedProductList = previousProductInState.push(product);
      dispatch(cartDataAdd(product));
      swal({
        TitleBar: "Success!!",
        text: "Product Added To Cart Successfully",
        icon: "success",
      });
    }
  };

  return (
    <>

      {products.map((product) => (
        <div
          key={product.id}
          className="shadow-lg.includes(product.id) hover:-translate-y-1 hover:scale-100 duration-300 sm:px-4"
        >
          <div className="overflow-hidden">
            <Link
              to={`${services.getProductDetails}/${product.id}`}
            >
              <img className="min-w-[100%] sm:min-w-[100%] md:min-w-[100%]" src={`${image_base_URL}${product.product_image}`} alt="" />
            </Link>
            {/* <p className="px-[10px]">₹ {product.product_regular_price}</p> */}
          </div>
          <div className='bg-primary'>
            <Link
              to={`${services.getProductDetails}/${product.id}`}
            >
              <h3 className="pt-2 px-4 font-normal text-[14px] text-white">
                {product.product_name}
              </h3>
            </Link>
          </div>
          <div className="flex px-4 py-2 justify-between items-center bg-primary border border-primary">
            <div className='basis-[40%] text-white font-bold'>
              <span className="text-white font-bold">
                ₹{" "}
                {product?.product_sale_price
                  ? product?.product_sale_price
                  : product?.product_regular_price}
              </span>
            </div>
            <div className='basis-[60%] text-right text-white font-bold'>
              Sale Point - {product.product_sale_point}
            </div>
          </div>
          <div className="flex px-4 py-2 justify-between items-center border border-primary">
            <div>
              {data.cart.filter((item) => item.id === product.id).length >
                0 && (
                  <p className="m-[0px] text-[13px] font-medium text-primary cursor-pointer"
                  // onClick={() => handleAddToCart(product)}
                  >
                    <Link to="/cart">
                      View Cart
                    </Link>
                  </p>
                )}
              {!data.cart.filter((item) => item.id === product.id)
                .length > 0 && (
                  <p
                    className="m-[0px] text-[13px] font-medium text-primary cursor-pointer"
                    onClick={() => handleAddToCart(product)}
                  >
                    ADD TO CART
                  </p>
                )}
            </div>

            <p>|</p>

            <div>
              <p
                className="m-[0px] text-[13px] font-medium text-primary cursor-pointer"
                onClick={() =>
                  redirectToPage(
                    `${services.getProductDetails}/${product.id}`
                  )
                }
              >
                KNOW MORE
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default HomeProducts