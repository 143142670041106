import React, { useState, useEffect } from "react";
import { services } from "../services";
import axios from "axios";

const Offers = () => {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(services.getOffers);
        setOffers(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchOffers();
  }, []);

  // Function to remove HTML tags from a string
  const removeHTMLTags = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  return (
    <>
      <div className="max-w-[1200px] mx-auto px-4 pt-12 pb-6 sm:pt-12 sm:pb-2">
        <h1 className="text-[32px] text-center">
          "The Hottest Offers That Will Leave You Speechless!"
        </h1>
      </div>
      <div className="container mx-auto py-8">
        <div className="grid grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:m-4 gap-4">
          {offers.map((offer) => (
            <div
              key={offer.id}
              className="bg-white shadow-lg rounded-lg overflow-hidden border-2 border-grey-500"
            >
              <img
                src={offer.offer_image}
                className="w-full h-auto object-cover pt-4"
              />
              <div className="p-8 ">
                <h3 className="text-2xl font-semibold pb-4">
                  {offer.offer_title}
                </h3>
                <p className="text-gray-600 font-bold">
                  {removeHTMLTags(offer.offer_short_description)}
                </p>
                <p className="text-gray-600">
                  {removeHTMLTags(offer.offer_long_description)}
                </p>
                <div className="mt-4">
                  <p className="text-primary font-bold pb-4">
                    Offer Valid From: {offer.offer_valid_from}
                  </p>
                  <hr />
                  <p className="text-primary font-bold pt-4">
                    Offer Valid To: {offer.offer_valid_to}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Offers;
