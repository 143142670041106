import React from 'react'
import { Link } from 'react-router-dom';
// import logo from '../assets/logo.jpeg'
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'

const BonusDividents = () => {
    return (
        <>
            <TitleBar pagetitle="For Bonus and Dividends" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Bonus and Dividends Terms</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='py-6 text-lg'>लाभांश और बोनस के भुगतान सम्बन्धी नियम-</h2>
                    {/* <p className='text-justify text-sm py-6'>
                You can find all the Terms, Conditions and Policies related to company. For any specific query, kindly get in touch with us.
            </p> */}
                    <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">

                        <li className='p-1'>
                            व्यक्तिगत सेल पर मिलने वाले लाभांश का भुगतान IPS चार्ट के अनुसार मासिक आधार पर (1 तारीख से 5 तारीख तक) किया जायेगा.
                        </li>

                        <li className='p-1'>
                            ग्रुप सेल पर मिलने वाला लाभांश का भुगतान GSC चार्ट के अनुसार मासिक ग्रुप सेल के आधार  पर देय प्रतिशत और SP(Sale Point) के अनुसार देय होगा , जिसका भुगतान मासिक आधार पर (1 तारीख से 5 तारीख तक) किया जायेगा.
                        </li>

                        <li className='p-1'>
                        आपकी आय पर मिलने वाले बोनस का भुगतान GSC चार्ट  के अनुसार मासिक आधार पर देय प्रतिशत के अनुसार देय होगा, जिसका भुगतान आगामी माह की 1 तारीख से 5 तारीख तक किया जायेगा.
                        </li>

                        <li className='p-1'>
                            कोई भी भुगतान नकद धनराशी के रूप में नहीं किया जायेगा.
                        </li>

                        <li className='p-1'>
                            प्रत्येक भुगतान नियमानुसार TDS काट कर ही किया जाएगा.
                        </li>

                    </ol >
                </div>
                <div className='basis-[25%] h-[450px] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>

                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div>
        </>
    )
}

export default BonusDividents