import React from 'react'
import { Link } from 'react-router-dom';
import TitleBar from '../component/title/TitleBar'
import FeatuteImage from '../assets/blog/women_health.webp'

const WomensHealth = () => {
    return (
        <>
            <TitleBar pagetitle="Women's Health" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to="/">Blogs</Link> &gt;
                    <Link to=""> Women's Health</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-12 flex sm:flex-col lg:flex-row'>
                <div className='basis-[70%] px-8'>
                    <img src={FeatuteImage} alt="/Swatee Logo" />
                    <h1 className='pt-6 text-[30px] py-8 text-primary'>
                        Women's Health
                    </h1>
                    <p className='text-justify pb-6'>
                        महिलाओं और पुरुषों की स्वास्थ्य संबंधी समस्याएं एक-दूसरे से अलग होती हैं। ऐसे में दोनों को होने वाली बीमारियां और उनके स्वास्थ्य पर पड़ने वाले प्रभाव भी अलग होते हैं। लेकिन सवाल यह है कि आखिर दोनों की स्वास्थ्य समस्याएं विपरीत क्यों हैं? दुनिया में सभी बमारियों का प्रभाव पुरुषों और महिलाओं पर अलग-अलग तरीके से दिखता है।
                    </p>
                    <p className='text-justify pb-6'>
                        <a className='text-primary bold' href="https://www.myupchar.com/women-health/masik-dharm-periods-in-hindi" target='_blank'>मासिक धर्म </a>, <a className='text-primary bold' href="https://www.myupchar.com/pregnancy" target='_blank'>गर्भावस्था</a>, प्रसव और <a className='text-primary bold' href="https://www.myupchar.com/women-health/menopause-ke-lakshan-in-hindi" target='_blank'>रजोनिवृत्ति </a> महिलाओं के जीवन का हिस्सा हैं, जो उन्हें पुरुषों से अलग बनाती हैं, ऐसे में उनकी <a className='text-primary bold' href="https://www.myupchar.com/disease" target='_blank'>बीमारियां </a>और शरीर पर पड़ने वाला इसका प्रभाव भी अलग होता है। कई ऐसी बमारियां भी हैं जो सिर्फ महिलाओं को प्रभावित करती हैं।
                    </p>

                    <p className='text-justify pb-6'>
                        दुनिया के अधिकांश हिस्सों और संस्कृतियों में पुरुषों की तुलना में महिलाओं की सामाजिक स्थिति थोड़ी हल्की है। यही वजह है कि महिलाओं के स्वास्थ्य संबंधी समस्याओं, उनके उपचार और देखभाल पर ज्यादा ध्यान नहीं दिया गया। समय बढ़ता गया, लेकिन महिला सशक्तीकरण, शिक्षा और स्वास्थ्य सुविधाओं में बहुत ज्यादा सुधार नहीं हो सका। इसकी वजह से वह भेदभाव का शिकार होती रहीं। यही वजह है कि विश्व स्वास्थ्य संगठन (डब्ल्यूएचओ) और दुनिया के सभी देश लैंगिक असमानता को दूर करने के साथ महिलाओं को बेहतर स्वास्थ्य प्रदान करने के उपाय कर रही हैं।
                    </p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4 '>
                        <li className='p-1'><a className='underline' href="https://www.myupchar.com/women-health#%E0%A4%AE%E0%A4%B9%E0%A4%BF%E0%A4%B2%E0%A4%BE%E0%A4%93%E0%A4%82-%E0%A4%95%E0%A5%87-%E0%A4%B8%E0%A5%8D%E0%A4%B5%E0%A4%BE%E0%A4%B8%E0%A5%8D%E0%A4%A5%E0%A5%8D%E0%A4%AF-%E0%A4%B8%E0%A5%81%E0%A4%A7%E0%A4%BE%E0%A4%B0-%E0%A4%AA%E0%A4%B0-%E0%A4%B5%E0%A4%BF%E0%A4%B6%E0%A5%87%E0%A4%B7-%E0%A4%A7%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%A8-%E0%A4%A6%E0%A5%87%E0%A4%A8%E0%A5%87-%E0%A4%95%E0%A5%80-%E0%A4%9C%E0%A4%B0%E0%A5%82%E0%A4%B0%E0%A4%A4-%E0%A4%95%E0%A5%8D%E0%A4%AF%E0%A5%8B%E0%A4%82" target='_blank'>महिलाओं के स्वास्थ्य सुधार पर विशेष ध्यान देने की जरूरत क्यों?</a></li>
                        <li className='p-1'><a className='underline' href="https://www.myupchar.com/women-health#%E0%A4%B5%E0%A4%BF%E0%A4%B6%E0%A5%87%E0%A4%B7-%E0%A4%B0%E0%A5%82%E0%A4%AA-%E0%A4%B8%E0%A5%87-%E0%A4%AE%E0%A4%B9%E0%A4%BF%E0%A4%B2%E0%A4%BE%E0%A4%93%E0%A4%82-%E0%A4%95%E0%A5%8B-%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%AD%E0%A4%BE%E0%A4%B5%E0%A4%BF%E0%A4%A4-%E0%A4%95%E0%A4%B0%E0%A4%A8%E0%A5%87-%E0%A4%B5%E0%A4%BE%E0%A4%B2%E0%A5%80-%E0%A4%AC%E0%A5%80%E0%A4%AE%E0%A4%BE%E0%A4%B0%E0%A4%BF%E0%A4%AF%E0%A4%BE%E0%A4%82" target='_blank'>विशेष रूप से महिलाओं को प्रभावित करने वाली बीमारियां</a></li>
                        <li><a className='underline' href="https://www.myupchar.com/women-health#%E0%A4%AA%E0%A5%81%E0%A4%B0%E0%A5%81%E0%A4%B7%E0%A5%8B%E0%A4%82-%E0%A4%95%E0%A5%80-%E0%A4%A4%E0%A5%81%E0%A4%B2%E0%A4%A8%E0%A4%BE-%E0%A4%AE%E0%A5%87%E0%A4%82-%E0%A4%AE%E0%A4%B9%E0%A4%BF%E0%A4%B2%E0%A4%BE%E0%A4%93%E0%A4%82-%E0%A4%95%E0%A5%8B-%E0%A4%B5%E0%A4%BF%E0%A4%B6%E0%A5%87%E0%A4%B7-%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%AD%E0%A4%BE%E0%A4%B5%E0%A4%BF%E0%A4%A4-%E0%A4%95%E0%A4%B0%E0%A4%A8%E0%A5%87-%E0%A4%B5%E0%A4%BE%E0%A4%B2%E0%A5%80-%E0%A4%AC%E0%A5%80%E0%A4%AE%E0%A4%BE%E0%A4%B0%E0%A4%BF%E0%A4%AF%E0%A4%BE%E0%A4%82" target="_blank" rel="noopener noreferrer">पुरुषों की तुलना में महिलाओं को विशेष प्रभावित करने वाली बीमारियां</a></li>
                        <li className='p-1'><a className='underline' href="https://www.myupchar.com/women-health#%E0%A4%AE%E0%A4%B9%E0%A4%BF%E0%A4%B2%E0%A4%BE-%E0%A4%B8%E0%A5%8D%E0%A4%B5%E0%A4%BE%E0%A4%B8%E0%A5%8D%E0%A4%A5%E0%A5%8D%E0%A4%AF-%E0%A4%95%E0%A5%87-%E0%A4%9A%E0%A5%81%E0%A4%A8%E0%A5%8C%E0%A4%A4%E0%A5%80%E0%A4%AA%E0%A5%82%E0%A4%B0%E0%A5%8D%E0%A4%A3-%E0%A4%95%E0%A4%BE%E0%A4%B0%E0%A4%95" target="_blank" rel="noopener noreferrer">महिला स्वास्थ्य के चुनौतीपूर्ण कारक</a></li>
                        <li className='p-1'> <a className='underline' href="https://www.myupchar.com/women-health#%E0%A4%AE%E0%A4%B9%E0%A4%BF%E0%A4%B2%E0%A4%BE%E0%A4%93%E0%A4%82-%E0%A4%95%E0%A5%87-%E0%A4%B8%E0%A5%8D%E0%A4%B5%E0%A4%BE%E0%A4%B8%E0%A5%8D%E0%A4%A5%E0%A5%8D%E0%A4%AF-%E0%A4%B8%E0%A4%82%E0%A4%AC%E0%A4%82%E0%A4%A7%E0%A5%80-%E0%A4%AE%E0%A5%81%E0%A4%A6%E0%A5%8D%E0%A4%A6%E0%A5%87" target="_blank" rel="noopener noreferrer">महिलाओं के स्वास्थ्य संबंधी मुद्दे</a></li>
                    </ul>

                    <h1 className='pt-6 text-[30px] py-8 text-primary'>
                        महिलाओं के स्वास्थ्य सुधार पर विशेष ध्यान देने की जरूरत क्यों?
                    </h1>
                    <p className='text-justify pb-6'>
                        संयुक्त राष्ट्र द्वारा जारी किए गए आंकड़ों के मुताबिक दुनिया की कुल आबादी का 49.6 प्रतिशत हिस्सा महिलाओं का है। साल 2011 में हेल्थ केयर वीमेन इंटरनेशनल जर्नल में प्रकाशित एक अध्ययन से पता चलता है कि दुनिया के श्रम विभाग में महिलाओं की दो-तिहाई हिस्सेदारी है, लेकिन इसके विपरीत उनकी आमदनी कुल आय की केवल 10 फीसद ही है। इस डेटा में घरेलू कामकाज में लगी महिलाओं को शामिल नहीं किया गया है।
                    </p>

                    <p className='text-justify pb-6'>
                        दुनिया की आबादी का इतना बड़ा हिस्सा होने और विकास में महत्वपूर्ण योगदान देने के बावजूद महिलाओं को उस प्रकार से स्वास्थ्य सुविधाएं नहीं मिल पाईं, जिनकी वे ​वास्तविक हकदार थीं। महिला और पुरुष व्यवहारिक और शारीरिक दृष्टि से अलग हैं। इसका अर्थ है कि महिलाओं की आवश्यकताओं को कई सामाजिक पहलुओं पर वन साइज फिट ऑल के आधार पर पूरा नहीं किया जा सकता है।
                    </p>

                    <p className='text-justify pb-6'>
                        महिलाओं के स्वास्थ्य के मुद्दों को बेहतर तरीके से समझा जाना चाहिए, क्योंकि उनका अच्छा स्वास्थ्य (या इसकी कमी) मनुष्यों की अगली पीढ़ी के निर्माण में महत्वपूर्ण भूमिका निभाती है। महिलाओं के स्वास्थ्य पर ध्यान देने की आवश्यकता है, खासकर तब जब पुरुषों से इतर <a className='text-primary bold' href="https://www.myupchar.com/women-health" target="_blank" rel="noopener noreferrer">महिलाओं को विशष रूप से कई समस्याओं का सामना</a> करना पड़ता है।
                    </p>

                    <h1 className='pt-6 text-[30px] py-8 text-primary'>
                        विशेष रूप से महिलाओं को प्रभावित करने वाली बीमारियां
                    </h1>
                    <p className='text-justify pb-6'>
                        पुरुषों की तुलना में महिलाओं का स्वास्थ्य और उनकी आवश्यकताएं अलग होती हैं। मासिक धर्म, गर्भावस्था, प्रसव और रजोनिवृत्ति जैसे जैविक चरणों का अनुभव कर वह कई प्रकार की स्वास्थ्य चुनौतियों का सामना करती हैं। नीचे दी गई वह समस्याएं हैं, जिनका सामना अकेले महिलाओं को करना पड़ता है।
                    </p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4 '>
                        <li className='p-1'>स्त्रीरोग संबंधी स्वास्थ्य समस्याएं जैसे <a className='underline' href="https://www.myupchar.com/women-health/irregular-periods-reasons-and-treatment-in-hindi" target="_blank" rel="noopener noreferrer">अनियमित पीरियड्स</a>,<a className='underline' href="https://www.myupchar.com/disease/bacterial-vaginosis" target="_blank" rel="noopener noreferrer"> बैक्टीरियल वजिनोसिस</a>,  <a className='underline' href="https://www.myupchar.com/disease/polycystic-ovary-syndrome-pcos" target="_blank" rel="noopener noreferrer">पीसीओएस</a>, <a className='underline' href="https://www.myupchar.com/disease/endometriosis" target="_blank" rel="noopener noreferrer">एंडोमेट्रियोसिस</a>, एडिनोमायोसिस, <a className='underline' href="https://www.myupchar.com/disease/uterine-fibroids" target="_blank" rel="noopener noreferrer">यूटेराइन फाइब्रॉयड और वुल्वोडनिया जैसे विकार</a>।</li>
                        <li className='p-1'>गर्भावस्था से संबंधित समस्याएं जैसे प्रसव के बाद की देखभाल, <a className='underline' href="https://www.myupchar.com/pregnancy/garbhpat-abortion-in-hindi" target="_blank" rel="noopener noreferrer">गर्भपात</a>, <a className='underline' href="https://www.myupchar.com/pregnancy/preterm-birth-in-hindi" target="_blank" rel="noopener noreferrer">समय से पहले बच्चे का जन्म</a>, <a className='underline' href="https://www.myupchar.com/surgery/c-section-cesarean-section" target="_blank" rel="noopener noreferrer">सिजेरियन सेक्शन</a>, जन्म दोष, <a className='underline' href="https://www.myupchar.com/motherhood/common-breastfeeding-problems-and-solutions-in-hindi" target="_blank" rel="noopener noreferrer">स्तनपान संबंधी समस्या</a> <a className='underline' href="https://www.myupchar.com/motherhood/delivery-ke-baad-depression-in-hindi" target="_blank" rel="noopener noreferrer">और प्रसव के बाद अवसाद की अवस्था।</a></li>
                        <li><a className='underline' href="https://www.myupchar.com/disease/ovarian-cancer" target="_blank" rel="noopener noreferrer">ओवेरियन कैंसर</a> और <a className='underline' href="https://www.myupchar.com/disease/cervical-cancer" target="_blank" rel="noopener noreferrer">सर्वाइकल कैंसर।</a></li>
                        <li className='p-1'><a className='underline' href="https://www.myupchar.com/disease/turner-syndrome" target="_blank" rel="noopener noreferrer">टर्नर सिंड्रोम </a>और रेट्स सिंड्रोम जैसे विकार।</li>
                        <li className='p-1'> हिंसा जैसे महिला जननांग विकृति और शिशु हत्या।</li>
                    </ul>

                    <h1 className='pt-6 text-[30px] py-8 text-primary'>
                        पुरुषों की तुलना में महिलाओं को विशेष प्रभावित करने वाली बीमारियां
                    </h1>
                    <p className='text-justify pb-6'>
                        ऐसी कई बीमारियां हैं जो पुरुषों और महिलाओं दोनों को हो सकती हैं, लेकिन इसका महिला पर होने वाला असर अलग तरीके का होता है। इन मामलों में दोनों के लक्षण में समानता हो सकती है, लेकिन उसके उपचार और बीमारी से उबरने के लिए देखभाल व आवश्यक ​परिस्थितियां अलग होती हैं। निम्नलिखित बीमारियां महिलाओं को अलग रूप से प्रभावित करती हैं।
                    </p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4 '>
                        <li className='p-1'>शराब की आदत का प्रभाव पुरुषों की तुलना में महिलाओं पर अधिक पड़ता है।&nbsp;<a className='underline' href="https://www.myupchar.com/disease/alcoholism">शराब की लत</a>&nbsp;के चलते उनमें&nbsp;<a className='underline' href="https://www.myupchar.com/disease/breast-cancer">स्तन कैंसर</a>,&nbsp;<a className='underline' href="https://www.myupchar.com/disease/heart-disease">दिल संबंधी रोग</a>&nbsp;और भ्रूण के अल्कोहल सिंड्रोम (जहां माँ के शराब पीने की आदतें शिशुओं के मस्तिष्क को क्षति पहुंचा सकती है) जैसी समस्याएं हो सकती हैं।</li>
                        <li>हृदय रोग पुरुषों और महिलाओं को समान रूप से प्रभावित करते हैं, लेकिन पुरुषों की तुलना में महिलाओं में&nbsp;<a className='underline' href="https://www.myupchar.com/disease/heart-attack">दिल का दौरा पड़ने</a>&nbsp;से मौत की आशंका अधिक रहती है।</li>
                        <li>पुरुषों की तुलना में महिलाओं को अवसाद और&nbsp;<a className='underline' href="https://www.myupchar.com/disease/anxiety">चिंता&nbsp;</a>होने की समस्या अधिक होती है,&nbsp;<a className='underline' href="https://www.myupchar.com/motherhood/delivery-ke-baad-depression-in-hindi">प्रसव के बाद अवसाद</a>&nbsp;का डर महिलाओं में अधिक रहता है।</li>
                        <li>पुरुषों की तुलना में महिलाओं में&nbsp;<a className='underline' href="https://www.myupchar.com/disease/arthritis">गठिया</a>&nbsp;की समस्या होने का खतरा अधिक रहता है।</li>
                        <li><a className='underline' href="https://www.myupchar.com/sexual-health/std-sexually-transmitted-diseases-in-hindi">यौन संचारित रोग&nbsp;</a>(एसटीडी) पुरुषों और महिलाओं दोनों को प्रभावित करते हैं, लेकिन उपचार में देरी से महिलाओं में&nbsp;<a className='underline' href="https://www.myupchar.com/disease/infertility">बांझपन</a>&nbsp;हो सकता है। महिलाओं में इसके लक्षण कई बार बहुत स्पष्ट नजर नहीं आते हैं, जिनके चलते उनका उपचार नहीं हो पाता है।</li>
                        <li>अमेरिकन साइकोलॉजिकल एसोसिएशन (एपीए) के एक सर्वेक्षण के अनुसार, महिलाओं में तनाव बढ़ रहा है और जिससे उनमें बांझपन होने का खतरा अधिक रहता है।</li>
                        <li>पुरुषों की तुलना में महिलाएं&nbsp;<a className='underline' href="https://www.myupchar.com/disease/stroke">स्ट्रोक</a>&nbsp;का शिकार ज्यादा होती हैं। ऐसा इसलिए है क्योंकि जहां स्ट्रोक का प्रमुख कारण परिवारिक इतिहास,&nbsp;<a className='underline' href="https://www.myupchar.com/disease/blood-pressure-high">हाई ब्लडप्रेशर</a>&nbsp;और&nbsp;<a className='underline' href="https://www.myupchar.com/disease/high-cholesterol">हाई कोलेस्ट्रॉल</a>&nbsp;होता है, वहीं गंर्भनिरोधक दवाओं,&nbsp;<a className='underline' href="https://www.myupchar.com/therapy/hormone-replacement-therapy">हार्मोन रिप्लेसमेंट थेरेपी</a>&nbsp;और गर्भावस्था के चलते महिलाओं में यह खतरा बढ़ जाता है।</li>
                        <li><a className='underline' href="https://www.myupchar.com/disease/painful-urination">पेशाब</a>&nbsp;के तरीके के चलते पुरुषों की तुलना में महिलाओं को&nbsp;<a className='underline' href="https://www.myupchar.com/disease/urinary-tract-infection-uti">मूत्रमार्ग संक्रमण</a>&nbsp;(यूटीआई) होने का खतरा अधिक रहता है।</li>
                    </ul>

                    <h1 className='pt-6 text-[30px] py-8 text-primary'>
                        महिला स्वास्थ्य के चुनौतीपूर्ण कारक
                    </h1>
                    <p className='text-justify pb-6'>
                        भले ही दुनिया की आधी आबादी महिलाओं की हो,  फिर भी भारत सहित कई देशों में उनके स्वास्थ्य के मुद्दों पर पर्याप्त ध्यान नहीं दिया जा रहा है। निम्न कारक इस स्थिति को उत्पन्न करने के लिए जिम्मेदार हैं।
                    </p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li><strong>सामाजिक-सांस्कृतिक कारण</strong><br></br>

                            विश्व के अधिकांश हिस्सों में पुरुषों की तुलना में महिलाओं को वह सामाजिक अधिकार नहीं मिल सके, जो मिलने चाहिए थे। इस लैंगिक असमानता ने महिलाओं की शिक्षा, आर्थिक, सामाजिक स्वतंत्रता और स्वास्थ्य सेवाओं को कमजोर बनाया है। दुनिया के कई हिस्सों में महिलाओं को अकेले यात्रा करने, स्वास्थ्य के लिए भी दूसरों पर आश्रित रहने, यहां तक कि परिवार नियोजन के मामले में भी अपने पक्ष को रखने का अधिकार नहीं है। समाज में लड़के को प्राथमिकता देने के चलते न केवल कन्या भ्रूण हत्या और शिशु हत्या बढ़ी है, साथ ही स्वच्छता, पोषण के मामले में महिलाएं आजीवन उपेक्षा का शिकार भी रही हैं।</li>
                    </ul>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li><strong>आर्थिक कारक</strong><br></br>
                            यूनाइटेड नेशनंस वूमेन और विश्व बैंक द्वारा 2013 में जारी की गई एक रिपोर्ट के अनुसार दुनियाभर में गरीबी से जूझ रहे 767 मिलियन (76.7 करोड़) लोगों में एक बड़ा हिस्सा महिलाओं और बच्चों का है। गरीबी का सीधा असर स्वास्थ्य सेवाओं पर पड़ता है। इसके अतिरिक्त, 2011 में हेल्थ केयर वुमेन इंटरनेशनल जर्नल में प्रकाशित अध्ययन के अनुसार, काम के घंटों में दुनिया में दो-तिहाई हिस्सेदारी रखने वाली महिलाएं आर्थिक रूप से कुल आय का सिर्फ 10 फीसदी ही कमाती हैं।</li>
                    </ul>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li><strong>वैज्ञानिक और चिकित्सा अनुसंधान</strong><br></br>
                            2003 में जर्नल ऑफ क्लिनिकल इन्वेस्टिगेशन में प्रकाशित एक अध्ययन के अनुसार चिकित्सा, उपचार और स्वास्थ्य संबंधी तकनीकों के आधुनिकीकरण के निर्धारण के लिए किए गए सर्वे में महिलाओं को कम आंका जाता है। ऐसे में महिलाओं के स्वास्थ्य के लिए जरूरी अवयव कहीं न कहीं रह जाते हैं।</li>
                    </ul>


                    <h1 className='pt-6 text-[30px] py-8 text-primary'>
                        महिलाओं के स्वास्थ्य संबंधी मुद्दे
                    </h1>
                    <p className='text-justify pb-6'>
                        महिलाओं में आमतौर पर होने वाली स्वास्थ्य समस्याओं को ध्यान में रखते हुए डब्ल्यूएचओ जैसी शीर्ष संस्थाओं ने इनपर विशेष ध्यान देने की अपील की है। महिलाओं के स्वास्थ्य से जुड़े निम्नलिखित 10 मुद्दों पर भारत सहित दुनियाभर के देशों को ध्यान देने की आवश्यकता है।
                    </p>

                    <p><strong>1. कैंसर</strong></p>

                    <p className='text-justify pb-6'>महिलाओं में दो प्रकार के कैंसर (स्तन और सर्वाइकल) होने का खतरा सबसे अधिक रहता है। प्रति वर्ष के आंकड़ों पर नजर डालें तो पता चलता है कि हर साल लगभग 5 लाख महिलाएं इन दोनों प्रकार के कैंसर के चलते मौत का शिकार हो जाती हैं। अगर इन कैंसर के लक्षण समय रहते पता चल जाएं तो उनके ठीक होने की संभावना बढ़ जाती है। ऐसा करने के लिए कई महत्वपूर्ण विषयों पर विशेष रूप से ध्यान देने की जरूरत होती है।</p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>स्तन और सर्वाइकल के कैंसर के बारे में जागरुकता</li>
                        <li>कैंसर की स्क्रीनिंग और इलाज के विशेष रोकथाम और सस्ते इलाज</li>
                        <li><a href="https://www.myupchar.com/disease/hpv-human-papillomavirus">ह्यूमन पेपिलोमावायरस&nbsp;</a>(एचपीवी) के टीकाकरण की आसानी से उपलब्धता</li>
                        <li>स्तन कैंसर में&nbsp;<a href="https://www.myupchar.com/surgery/breast-cancer">मास्टेक्टॉमी</a>&nbsp;(स्तनों को काटकर अलग करना) से जुड़े मिथों को दूर करना</li>
                    </ul>

                    <p><strong>2. प्रजनन से संबंधित जानकारियां</strong></p>

                    <p className='text-justify pb-6'>प्रजनन और उससे जुड़े कई पहलुओं पर कई अध्ययन किए जा चुके हैं। प्रजनन प्रणाली एक जटिल प्रक्रिया है। वैसे तो यूटीआई और बैक्टीरियल संक्रमण महिला की गर्भधारण शक्ति को प्रभावित नहीं करते हैं। हालांकि, भविष्य में इसके कई दुष्प्रभाव देखने को मिल सकते हैं। प्रजनन स्वास्थ्य से जुड़े तथ्यों को जानने और समझने के लिए निम्न विषयों पर ध्यान देना आवश्यक है।</p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>प्रजनन स्वास्थ्य के बारे में फैली अफवाहों को दूर करना</li>
                        <li>प्रजनन और उससे जुड़ी स्वास्थ्य समस्याओं के बारे में लोगों को जागरुक करना</li>
                        <li>गर्भनिरोधकों के प्रयोग के बारे में जागरुकता और लोगों तक इसकी उपलब्धता को आसान बनाना।</li>
                        <li>बच्चों को यौन शिक्षा और इससे जुड़ी जानकारियां देना।</li>
                    </ul>

                    <p><strong>3.&nbsp;संबंधी स्वास्थ्य जानकारियां</strong></p>

                    <p className='text-justify pb-6'>प्रसवपूर्व और प्रसवोत्तर देखभाल से ही&nbsp;<a href="https://www.myupchar.com/childcare">शिशु के स्वास्थ्य</a>&nbsp;को बेहतर बनाने के साथ साथ विश्वस्तर पर मातृ मृत्यु की संख्या को कम किया जा सकता है। इस संबंध में डॉक्टरों के साथ-साथ दोस्तों और परिवार के देखभाल की एक बड़ी भूमिका रहती है। मातृ स्वास्थ्य को बढ़ावा देने के लिए निम्नलिखित कदम उठाए जाने चाहिए।</p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>प्रसव पूर्व और प्रसव के बाद की देखभाल के बारे में उचित जानकारी</li>
                        <li>गर्भावस्था से पहले और उसके दौरान स्क्रीनिंग, परीक्षण और&nbsp;<a href="https://www.myupchar.com/tips/vaccination-in-hindi">टीकाकरण</a>&nbsp;के सस्ते इंतजाम</li>
                        <li>पोषण, व्यायाम और&nbsp;<a href="https://www.myupchar.com/disease/mental-illness/how-to-become-mentally-strong">मानसिक स्वास्थ्य</a>&nbsp;की देखभाल पर विशेष जोर</li>
                        <li><a href="https://www.myupchar.com/pregnancy/garbhpat-abortion-in-hindi">गर्भपात&nbsp;</a>की स्थिति में मां का विशेष शारीरिक ध्यान और ख्याल रखना</li>
                        <li>लड़कियों के जन्म से जुड़े मिथों और पुरानी सोच को दूर करना</li>
                    </ul>

                    <p><strong>4.&nbsp;एचआईवी /&nbsp;एड्स</strong></p>
                    <p className='text-justify pb-6'>जागरुकता के अभाव में इस बीमारी ने दुनियाभर में तेजी से अपने पांव फैलाए हैं।&nbsp;<a href="https://www.myupchar.com/disease/hiv-aids">ह्यूमन इम्यूनोडिफ़िशिएंसी वायरस (एचआईवी)</a>&nbsp;के पहले मामले के सामने आने के तीन दशक बाद भी अब तक इसके खतरे को कम नहीं किया जा सका है। युवा महिलाओं में इस संक्रमण का खतरा अधिक रहता है।&nbsp;<a href="https://www.myupchar.com/sexual-health">यौन शिक्षा</a>,&nbsp;<a href="https://www.myupchar.com/sexual-health/condom-in-hindi">कंडोम</a>&nbsp;की लोगों तक पहुंच न होना, साथ ही&nbsp;<a href="https://www.myupchar.com/sexual-health/how-to-have-safe-sex-in-hindi">सुरक्षित सेक्स</a>&nbsp;के बारे में जानकारियों के अभाव के चलते इस संक्रमण का फैलाव तेजी से हुआ। लोगों को जागरुक कर इस संक्रमण को रोकने के व्यापक प्रयास किए जाने की आवश्यक है।</p>

                    <p><strong>5.&nbsp;यौन संचारित रोग</strong></p>
                    <p className='text-justify pb-6'>जागरुकता के अभाव में इस बीमारी ने दुनियाभर में तेजी से अपने पांव फैलाए हैं।&nbsp;<a href="https://www.myupchar.com/disease/hiv-aids">ह्यूमन इम्यूनोडिफ़िशिएंसी वायरस (एचआईवी)</a>&nbsp;के पहले मामले के सामने आने के तीन दशक बाद भी अब तक इसके खतरे को कम नहीं किया जा सका है। युवा महिलाओं में इस संक्रमण का खतरा अधिक रहता है।&nbsp;<a href="https://www.myupchar.com/sexual-health">यौन शिक्षा</a>,&nbsp;<a href="https://www.myupchar.com/sexual-health/condom-in-hindi">कंडोम</a>&nbsp;की लोगों तक पहुंच न होना, साथ ही&nbsp;<a href="https://www.myupchar.com/sexual-health/how-to-have-safe-sex-in-hindi">सुरक्षित सेक्स</a>&nbsp;के बारे में जानकारियों के अभाव के चलते इस संक्रमण का फैलाव तेजी से हुआ। लोगों को जागरुक कर इस संक्रमण को रोकने के व्यापक प्रयास किए जाने की आवश्यक है।</p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>यौन शिक्षा को जानकारी।</li>
                        <li>कंडोम, गर्भनिरोधक के उपयोग से यौन संचारित रोगों को रोक जा सकता है।</li>
                        <li>कंडोम, गर्भ निरोधकों की आसानी से उपलब्धता को सुनिश्चित करना।</li>
                    </ul>


                    <p><strong>6.&nbsp;महिलाओं के साथ होने वाली हिंसा को रोकना</strong></p>
                    <p className='text-justify pb-6'>डब्ल्यूएचओ की रिपोर्ट के अनुसार, 50 वर्ष से कम आयु वाली हर तीन में से एक महिला को परिचित या किसी अजनबी से यौन हिंसा का शिकार होना पड़ता है। हिंसा चाहे शारीरिक हो या यौन प्रकृति की, इसका महिला के शारीरिक और मानसिक स्वास्थ्य पर गहरा प्रभाव पड़ता है। इसे दूर करने के लिए निम्न विषयों पर विशेष ध्यान देने की जरूरत है।</p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>बच्चों का यौन शिक्षा दें। पुरुषों और महिलाओं दोनों को यौन हिंसा को रोकने के बारे में शिक्षित किया जाना चाहिए।</li>
                        <li>प्रशासन और चिकित्सकों को महिलाओं के साथ होने वाले हिंसा के बारे में संवेदनशील बनाना।</li>
                    </ul>

                    <p>7.&nbsp;<strong>मानसिक स्वास्थ्य</strong></p>
                    <p className='text-justify pb-6'>डब्ल्यूएचओ की रिपोर्ट के मुताबिक अवसाद, चिंता और कई तरह की मनोदैहिक शिकायतें महिलाओं में अधिक देखने को मिलती हैं। इसका कई बार मानसिक स्वास्थ्य पर दीर्घकालिक असर देखने को मिलता है, जो जीवन को प्रभावित कर सकती है। यही कारण है कि महिलाओं में मानसिक स्वास्थ्य को लेकर विशेष ध्यान देने की जरूरत होती है।</p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>मानसिक स्वास्थ्य से संबंधित मुद्दों के बारे में बातचीत कर उसके निदान और उपचार के बारे में चिंतन करें।</li>
                        <li>इस तरह की समस्याओं से प्रभावित महिलाओं को सामाजिक स्तर पर सहायता प्रदान करें, जिससे वह जल्द से जल्द इससे बाहर आ सकें।</li>
                    </ul>

                    <p>8.&nbsp;<strong>गैर-संचारी रोग</strong></p>
                    <p className='text-justify pb-6'>महिलाओं में गैर-संचारी रोगों जैसे&nbsp;<a href="https://www.myupchar.com/disease/diabetes">मधुमेह</a>, हाई ब्लड प्रेशर,&nbsp;<a href="https://www.myupchar.com/disease/obesity/home-remedies">मोटापा</a>&nbsp;आदि तेजी से फैल रही बीमारियां हैं। इनकी रोकथाम के लिए निम्न बिंदुओं पर ध्यान देने की आवश्यकता होती है।</p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>बालिकाओं को उनके स्वास्थ्य के बारे में शिक्षित करें।</li>
                        <li>माता-पिता की जिम्मेदारी है कि वह लड़कियों को स्वास्थ्य और सुरक्षित स्वास्थ्य सेवाएं उपलब्ध कराएं।</li>
                        <li>स्वास्थ्य सुविधाओं की आसानी से पहुंच उपलब्ध कराना।</li>
                    </ul>

                    <p><strong>10.&nbsp;उम्र के साथ महिलाओं को होने वाली समस्याएं</strong></p>

                    <p>विकासशील समाज में उम्र बढ़ने के साथ महिलाएं काम के लिए घर से बाहर जाना बंद कर देती हैं। जिससे उनके लिए पेंशन, स्वास्थ्य बीमा और इससे जुड़ी सेवाओं का लाभ लेना मुश्किल हो जाता है। उम्र बढ़ने के साथ-साथ महिलाओं में पुरानी&nbsp;<a href="https://www.myupchar.com/disease/arthritis">गठिया</a>,&nbsp;<a href="https://www.myupchar.com/disease/osteoporosis">ऑस्टियोपोरोसिस</a>,&nbsp;<a href="https://www.myupchar.com/disease/cataract">मोतियाबिंद</a>,&nbsp;<a href="https://www.myupchar.com/disease/glaucoma">ग्लूकोमा</a>&nbsp;और&nbsp;<a href="https://www.myupchar.com/disease/kidney-diseases">गुर्दे की बीमारी</a>&nbsp;होनी आम समस्या है। स्वास्थ्य सेवा प्रदान करने के लिए निम्नलिखित कदम उठाए जाने चाहिए।</p>
                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>कम उम्र में ही लड़कियों को भविष्य की संभावित स्वास्थ्य समस्याओं के बारे में जागरुक करें। ताकि वह पर्याप्त सुरक्षा और रोकथाम के इंतजाम कर सकें।</li>
                        <li>सस्ती और आसानी से सुलभ स्वास्थ्य सेवा प्रदान करने की व्यवस्था की जानी चाहिए।</li>
                        <li>बुढ़ापे के दौरान महिलाओं को मानसिक और शारीरिक रूप से स्वस्थ रखने के लिए उनके खानपान और मानसिक स्वास्थ्य के लिए बेहतर माहौल दें।</li>
                    </ul>
                    {/* <ol className='text-[#5C727D] text-sm list-decimal mt-2 p-4 '>
                    <li className='p-1'>This company, SWATEE Health Solutions Private Limited, is a project of the SWATEE family.</li>
                    <li className='p-1'>The company's corporate office is located at H39, Shivalik Nagar, Haridwar, Uttarakhand, with the PIN code 249403.</li>
                    <li>The main branch office is situated near Gali No. 13 and 14, Chiddarwala, Punjab National Bank, on the main road between Rishikesh and Dehradun.</li>
                    <li className='p-1'>The director of the company is Miss Kusum Lata, and her permanent address is Nepali Farm, Rishikesh, Uttarakhand</li>
                    <li className='p-1'> The company is registered under the Company Act with registration number U74999UR2019OPC009795.</li>
                    <li className='p-1'>The PAN number of the company is ABCCS0684A, and the GSTIN is 05ABCCS0684A1ZS. </li>
                    <li className='p-1'>The company also holds an FSSAI License Number 12623005000005. </li>
                    <li className='p-1'>The company is also known by the abbreviation SHS. </li>
                </ol> */}
                </div>
                <div className='basis-[30%]  ml-4 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        IMPORATANT LINKS
                    </h2>
                    <ul className="text-primary px-8 py-8 list-disc">

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/terms-policies" >Swatee Terms and Policies</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/complaints-and-redressals" >Complaints and Redressals</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Advisory Committee</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/login" >Admin Login</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/return-policy" >Return Policy</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/shipping-policy" >Shipping Policy</Link>
                        </li>
                    </ul >
                    <h2 className='mt-12 py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        SWATEE GROUP
                    </h2>
                    <ul className="text-primary px-8 py-8 list-disc">

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/swatee-jyotish-peeth" >Swatee Jyotish Peeth</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Swatee Health Solutions PVT. LTD</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/swatee-yogpeeth" >Swatee YogPeeth</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/swatee-ayur-jyoti" >Swatee Ayur Jyoti</Link>
                        </li>
                    </ul >
                </div>
            </div >
        </ >
    )
}

export default WomensHealth
