import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { AiOutlineDash, AiOutlineSearch } from "react-icons/ai";
import TitleBar from "../component/title/TitleBar";

import ProductCategory from "../component/products/ProductCategory";
import ShopProducts from "../component/products/ShopProducts";
import { services } from "../services";
import axios from "axios";
import { useRef } from "react";
import { globalLoader } from "../actions";
import { useDispatch, useSelector } from "react-redux";

const Shop = () => {
  const { id } = useParams();

  const data = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const inputref = useRef();
  // Fetch product data
  useEffect(() => {
    if (id) {
      filterdata('cat_search', id);
    }
    else {
      fetchProducts(services.getProduct);
    }

  }, []);



  const fetchProducts = async (url) => {
    try {
      const response = await axios.get(url);
      if (response?.status !== "error") {
        setProducts(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterdata = (type, data = null) => {
    showLoader();
    console.log(type, data);
    if (type === "main_search") {
      data = inputref.current?.value;
      let url = `${services.getProduct}?filter_product_name=${data}`;
      fetchProducts(url);
    }
    if (type === "cat_search") {
      let url = `${services.getProduct}?filter_category_id=${data}`;
      fetchProducts(url);
    }
  };

  useEffect(() => {
    dispatch(globalLoader(true));
    setTimeout(() => {
      dispatch(globalLoader(false));
    }, 3000);
  }, []);

  const showLoader = () => {
    dispatch(globalLoader(true));
    setTimeout(() => {
      dispatch(globalLoader(false));
    }, 3000);
  };

  useEffect(() => {

  }, [id])

  return (
    <div>
      <TitleBar
        pagetitle="Swatee Health Solutions Products"
        breadcrumbs={
          <>
            <Link to="/">Home</Link> &gt;
            <Link to=""> Shop</Link>
          </>
        }
      />

      <div className="container py-4 flex justify-between my-12 sm:flex-col lg:flex-row">
        <div className="basis-[70%]">
          <div className="px-4 grid xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 xl:grid-col-4 gap-6 pb-20">
            <ShopProducts products={products} />
          </div>
        </div>
        <div className="basis-[30%] ml-8">

          {/* Search Products */}
          <div className="border-2 py-8 px-6 mb-8">
            <h3 className="pb-6 text-[16px]">
              {" "}
              <AiOutlineDash className="inline mr-4 text-primary text-2xl" />{" "}
              Search Product
            </h3>
            <div className="flex">
              <div className="mb-3 ">
                <div className="relative mb-4 flex items-stretch">
                  <input
                    type="search"
                    className="relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-[#f2f6f7] bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:text-neutral-200 dark:placeholder:text-neutral-200 placeholder:text-sm"
                    placeholder="Search Your Keyword"
                    aria-label="Search"
                    aria-describedby="button-addon1"
                    ref={inputref}
                  />
                  <button
                    className="relative z-[2] flex items-center rounded-r bg-primary px-6 py-2.5 text-2xl font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                    type="button"
                    id="button-addon1"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    onClick={() => filterdata("main_search")}
                  >
                    <AiOutlineSearch />
                  </button>

                </div>
                {/* clear filters Products */}
                <div>
                  <a
                    className="cursor-pointer bg-primary p-2 text-white"
                    onClick={() => fetchProducts(services.getProduct)}
                  >
                    Clean All
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="border-2 py-8 px-6 mb-8">
            <h3 className="pb-6 text-[16px]">
              {" "}
              <AiOutlineDash className="inline mr-4 text-primary text-2xl" />{" "}
              Product Categories{" "}
            </h3>
            <ProductCategory filterdata={filterdata} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
