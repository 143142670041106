import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { services } from "../../services";

const ContactForm = () => {
    let navigate = useNavigate();

    const [error, setError] = useState('');
    const [validation, setValidation] = useState('');
    const [loading, setLoading] = useState(false);


    const [user, setUser] = useState({
        full_name: '',
        email: '',
        mobile_no: '',
        subject: '',
        message: '',
    });

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // setValidation('');
        setLoading(true);

        try {
            const response = await axios.post('/send-mail', user);
            setUser(response)
            swal(
                'Message has been sent successfully',
                'We will get back to you as soon as possible.'
            );
            navigate('/');
        } catch (error) {
            console.error(error);
            // setValidation(error.response.data);
        }

        setLoading(false);
    };


    return (
        <>
            <form onSubmit={handleSubmit}>
                <input type="text" id="first_name" className="max-w-[48%] mr-[2%] h-[60px] border-[2px] border-[#e4ecf2] text-[#000] text-sm w-full p-6 mb-8" placeholder="Enter Full Name*" required
                    name='full_name'
                    value={user.full_name}
                    onChange={handleChange}
                />

                <input type="email" id="email" className="max-w-[48%]  ml-[2%]  h-[60px] border-[2px] border-[#e4ecf2] text-[#000] text-sm w-full p-6 mb-8" placeholder="Enter Email Address*" required
                    name='email'
                    value={user.email}
                    onChange={handleChange}
                />
                <br />

                <input type="text" id="subject" className="max-w-[48%] mr-[2%] h-[60px] border-[2px] border-[#e4ecf2] text-[#000] text-sm w-full p-6 mb-8" placeholder="Enter the Subject*" required
                    name='subject'
                    value={user.subject}
                    onChange={handleChange}
                />

                <input type="tel" id="phone" className="max-w-[48%] ml-[2%] h-[60px] border-[2px] border-[#e4ecf2] text-[#000] text-sm w-full p-6 mb-8" placeholder="Mobile No.*" required
                    name='mobile_no'
                    value={user.mobile_no}
                    onChange={handleChange}
                />

                <textarea className="border-[2px] border-[#e4ecf2] text-[#000] text-sm w-full p-6" placeholder="Enter your message/quiry or feedback here.."
                    name='message'
                    value={user.message}
                    onChange={handleChange}
                />

                <button type="submit" className="btn ">Submit Your Quiry </button>
                {loading && (
                    <p className='text-left'>Please Wait...</p>
                )}
                {error && <p className='text-[red] text-sm mt-[-20px] pb-6'>{error}</p>}
            </form>
        </>
    )
}

export default ContactForm