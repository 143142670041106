import React from 'react'
import { Link } from 'react-router-dom';
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'
import ContactForm from '../component/forms/ContactForm';


const SwateeYogpeeth = () => {
    return (
        <>
            <TitleBar pagetitle="Swatee Yogdan Seva Sansthan" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Swatee Yog Peeth</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='py-6 text-lg'>SWATEE Yogdan Seva Sansthan</h2>
                    <p className='text-justify text-sm py-2 text-[#4d4d4d]'>
                        This is an organization within the family that connects everyone through social service and provides support to those in need of educational, financial, or psychological assistance in society. Through this platform or institution, the Swatee family works to nurture and establish the intellectually capable and dedicated personalities of society in the mainstream. Its main work includes opposing social evils, teaching the art of maintaining social and family relationships, providing assistance and support to people as per their needs, and teaching people to live for the betterment of society. It was established in the year 2015, and Miss Kusum Rana serves as the Secretary, while Miss Poonam Gussain serves as the Treasurer, overseeing its management. In essence, "Swatee Yogdan Seva Sansthan" strives to empower social well-being.
                    </p>
                    <h3 className='py-6 text-lg'>For any quiry Call Us: <br /><span className='text-primary text-xl'><a href="tel:7055520015">(+91) 70555 20015</a></span></h3>
                    <h3 className='py-6 text-lg'>Visit our website for more details: <br /><span className='text-primary text-xl'><a href="http://swateeyogdansewasansthan.com/" target='_blank'>www.swateeyogdansewasansthan.com</a></span></h3>

                </div>
                <div className='basis-[25%] h-[450px] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>

                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div>

            {/* Contact Form  */}
            <div className='container px-4 py-12 flex items-center sm:flex-col lg:flex-row '>
                <div className='basis-[100%] shadow-md p-12 m-[10px]'>
                    <h3 className='py-2 border-l-2 border-primary mb-8'>
                        <span className='pl-2'>Submit Your Query</span>
                    </h3>
                    <h3 className='py-6 text-lg'>Use below form to reach us:</h3>

                    <div className='basis-[100%]'>

                        <ContactForm />

                    </div>
                </div>
            </div>
        </>
    )
}

export default SwateeYogpeeth
