import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { TfiControlForward } from "react-icons/tfi";

function FaqToggle({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="border">
            <button
                className="flex w-full p-4 focus:outline-none "
                onClick={toggleAccordion}
            >
                {isOpen ? (
                    <TfiControlForward className="w-4 h-4" />
                ) : (
                    <AiOutlinePlus className="w-4 h-4" />
                )}
                <h3 className="font-medium text-sm ml-6 pb-0 sm:text-left">{question}</h3>

            </button>
            {isOpen && (
                <div className="ml-14 pb-4">
                    <p className="text-gray-500 text-[13px]">{answer}</p>
                </div>
            )}
        </div>
    );
}

export default FaqToggle;
