import React from 'react'
import { Link } from 'react-router-dom';

import { AiOutlineMail } from 'react-icons/ai'
import { MdWifiCalling1, MdShareLocation } from 'react-icons/md'
import Iframe from 'react-iframe'

import TitleBar from '../component/title/TitleBar'
import ContactFomr from '../component/forms/ContactForm';

const Contact = () => {
    return (
        <div>
            <TitleBar pagetitle="Contact Us" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Contact</Link>
                </>}
            />


            <div className='container px-4 py-12 flex sm:flex-col lg:flex-row'>
                <div className='basis-[50%]'>
                    <div className='p-8 flex shadow-md m-[10px]'>
                        <div>
                            <AiOutlineMail className='text-[5rem] pb-6 text-primary' />
                        </div>
                        <div className='pl-8'>
                            <h3 className='pb-4 '>
                                Email Address
                            </h3>
                            <a className="hover:text-primary" href="mailto:swateehealthsolution@gmail.com">swateehealthsolution@gmail.com</a>
                        </div>

                        {/* <p>jobs@webexample.com</p> */}
                    </div>
                    <div className='p-8 flex shadow-md m-[10px]'>
                        <div>
                            <MdWifiCalling1 className='text-[5rem] pb-6 text-primary' />
                        </div>
                        <div className='pl-4'>
                            <h3 className='pb-4'>
                                Phone Number
                            </h3>
                            <a className="hover:text-primary" href="tel:7055520015">(+91) 70555 20015</a><br />
                            <a className="hover:text-primary" href="tel:8218112501">(+91) 82181 12501</a>
                        </div>
                    </div>
                    {/* <div className=' p-8 flex shadow-md m-[10px]'>
                        <div>
                            <MdShareLocation className='text-[5rem] pb-6 text-primary' />
                        </div>
                        <div className='pl-4'>
                            <h3 className='pb-4'>
                                Corporate Address
                            </h3>
                            <p>H39, Shivalik Nagar, Haridwar. Pin:249403 Uttrakhand</p>
                        </div>
                    </div> */}
                    <div className=' p-8 flex shadow-md m-[10px]'>
                        <div>
                            <MdShareLocation className='text-[5rem] pb-6 text-primary' />
                        </div>
                        <div className='pl-4'>
                            <h3 className='pb-4'>
                                Office Address
                            </h3>
                            <p>Gali no. 13 & 14, Main Road, Chiddarwala, Rishikesh, DehradunPin:249204 Uttrakhand</p>
                        </div>
                    </div>
                </div>
                {/* Contact Form */}
                <div className='basis-[50%]'>
                    <div className='shadow-md p-12 m-[10px]'>
                        <h3 className='py-2 border-l-2 border-primary mb-8'>
                            <span className='pl-2'>Get A Quote</span>
                        </h3>

                        <div className='basis-[100%]'>

                            <ContactFomr />

                        </div>
                    </div>
                </div>

            </div>

            {/* Contact Form  */}
            {/* <div className='container px-4 py-12 flex items-center sm:flex-col lg:flex-row '>
                <div className='basis-[100%] shadow-md p-12 m-[10px]'>
                    <h3 className='py-2 border-l-2 border-primary mb-8'>
                        <span className='pl-2'>Get A Quote</span>
                    </h3>

                    <div className='basis-[100%]'>

                        <ContactFomr />

                    </div>
                </div>
            </div> */}

            {/* google form */}
            <div className='container px-4 py-12 flex items-center sm:flex-col lg:flex-row'>
                {/* <div className='basis-[50%] m-[10px]'>
                    <div className='pl-4'>
                        <h3 className='pb-4'>
                            Corporate Address
                        </h3>

                    </div>

                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3457.4963397038337!2d78.06966752623438!3d29.936398872816163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390948f85bfaaaab%3A0xa7d3c7b308832741!2sSWATEE%20HEALTH%20SOLUTIONS!5e0!3m2!1sen!2sin!4v1679167007708!5m2!1sen!2sin"
                        width="100%"
                        height="350px"
                        id=""
                        className=""
                        display="block"
                        position="relative" />

                </div> */}
                <div className='basis-[100%] m-[10px]'>
                    <div className='pl-4'>
                        <h3 className='pb-4'>
                            Office Address
                        </h3>

                    </div>
                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3457.4963397038337!2d78.06966752623438!3d29.936398872816163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390948f85bfaaaab%3A0xa7d3c7b308832741!2sSWATEE%20HEALTH%20SOLUTIONS!5e0!3m2!1sen!2sin!4v1679167007708!5m2!1sen!2sin"
                        width="100%"
                        height="350px"
                        id=""
                        className=""
                        display="block"
                        position="relative" />
                </div>
            </div>
        </div>
    )
}

export default Contact
