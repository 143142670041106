import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { services } from "../../services";

const cartTotalAPI = "/get-cart-data";

const CartTotal = ({ couponData }) => {
  const [cartTotal, setCartTotal] = useState([]);
  const data = useSelector((state) => state.cartReducer);
  const [taxinfo, setTaxinfo] = useState({});

  let totalCartPayment = 0;
  data?.cart.map((item) => {
    totalCartPayment += parseInt(
      item?.newUpdatedPrice
        ? item?.newUpdatedPrice
        : item?.product_sale_price
          ? item?.product_sale_price
          : item?.product_regular_price
    );
  });

  useEffect(() => {
    let selectedData = data?.cart.map((item) => {
      return {
        product_id: item.id,
        coupon:{id:data?.coupon?.id},
        quantity: (item?.newQuantityAdded ? item?.newQuantityAdded : 1)
      };
    });
    const payload = {
      billingDetails: [],
      coupon: couponData,
      productDetails: selectedData,
    };

    axios
      .post(services.getTaxInfo, payload)
      .then((response) => {
        setTaxinfo(response.data);
        // setCartTotal(response.data.order);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data?.cart, couponData]);

  return (
    <>
      <table className="w-full border-b">
        <tbody className="text-[#5C727D] ">
          <tr className="border-b ">
            <td className="border-b bg-gray-50 p-3 text-md ">Cart Subtotal </td>
            <td className="border-b bg-gray-50 p-3 text-md text-right">
              ₹{" "}
              {(taxinfo?.calculate_cart_price?.mrp_total ? taxinfo?.calculate_cart_price?.mrp_total:0)}
            </td>
          </tr>
          <tr className="border-b">
            <td className="border-b bg-gray-50 p-3 text-md">Sale Discount </td>
            <td className="border-b bg-gray-50 p-3 text-md text-right">
              ₹ {taxinfo?.calculate_cart_price?.total_sale_discount_amount}
            </td>
          </tr>
          <tr className="border-b">
            <td className="border-b bg-gray-50 p-3 text-md">
              Coupon Discount{" "}
            </td>
            <td className="border-b bg-gray-50 p-3 text-md text-right">
              ₹ {taxinfo?.calculate_cart_price?.total_coupon_discount_amount}
            </td>
          </tr>
          <tr className="border-b ">
            <td className="border-b bg-gray-50 p-3 text-md ">GST </td>
            <td className="border-b bg-gray-50 p-3 text-md text-right">
              ₹ {taxinfo?.calculate_cart_price?.total_tax_amount}
            </td>
          </tr>
          <tr className="border-b ">
            <td className="border-b bg-gray-50 p-3 text-md">Shipping </td>
            <td className="border-b bg-gray-50 p-3 text-md text-right">
              ₹ {taxinfo?.calculate_cart_price?.shipping_charge}
            </td>
          </tr>
          <tr className="border-b font-bold">
            <td className="border-b bg-gray-50 p-3 text-md">Order Total </td>
            <td className="border-b bg-gray-50 p-3 text-md text-right">
              ₹ {taxinfo?.calculate_cart_price?.total_payable_amount}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default CartTotal;
