import React from 'react'
import { Link } from 'react-router-dom';
// import logo from '../assets/logo.jpeg'
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'
import ContactForm from '../component/forms/ContactForm';

const ComplaintsRedressal = () => {
    return (
        <>
            <TitleBar pagetitle="Complaints Redressal Policy" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Complaints Redressal Policy</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='py-6 text-lg'>शिकायत  एवं निवारण नीति – </h2>
                    <p className='text-justify text-sm p-2 text-[#4d4d4d]'>
                        किसी को आपसे शिकायत होना एक सामान्य प्रकिया प्रक्रिया हैं इसे अन्यथा ना लें, शिकायतकर्ता की शिकायत अनिवार्य रूप से सुनें और उसका समाधान या निवारण करने में अपना शतप्रतिशत सहयोग दें उसके लिए कंपनी द्वारा कुछ तरीके / नियम बताएँ हैं जो निम्न प्रकार से हैं –
                    </p>
                    <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">

                        <li className='p-1'>
                            उपभोक्ता के रूप में / प्रत्यक्ष विक्रेता के रूप में आपको कोई भी शिकायत हो तो आप अपनी शिकायत लिखित रूप से अपने अपलाइन के माध्यम से या complaint mail  द्वारा कम्पनी की शिकायत निवारण समिति को भेज सकते हैं.
                        </li>

                        <li className='p-1'>
                            यदि आपकी शिकायत पर १५ दिन की समयावधि में कोई संतोषजनक कार्यवाही नहीं होती हैं तो आप अपनी शिकायत सीधे कंपनी की Email: swateehealthsolution@gmail.com पर भेज सकते हैं.
                        </li>

                        <li className='p-1'>
                            यदि आगामी १५ दिनों की समयावधि में आपकी शिकायत पर यधोचित कार्यवाही नहीं होती हैं या आप संतुष्ट नहीं होते है तो आप अपनी शिकायत के सम्बन्ध में राज्य उपभोक्ता फोरम में शिकायत दर्ज करा सकते हैं.
                        </li>

                    </ol >

                </div>
                <div className='basis-[25%] h-[450px] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>

                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div>


            {/* Contact Form  */}
            <div className='container px-4 py-12 flex items-center sm:flex-col lg:flex-row '>
                <div className='basis-[100%] shadow-md p-12 m-[10px]'>
                    <h3 className='py-2 border-l-2 border-primary mb-8'>
                        <span className='pl-2'>आप निम्न फॉर्म के माध्यम से भी हम तक अपनी बात पंहुचा सकते है</span>
                    </h3>

                    <div className='basis-[100%]'>

                        <ContactForm />

                    </div>
                </div>
            </div>

        </>
    )
}

export default ComplaintsRedressal