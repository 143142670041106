import React from 'react'
import { Link } from 'react-router-dom'

import leaders from '../../assets/swatee-leaders-leads-from-front-with-text.png'
import whole from '../../assets/whole-world-of-opportunities-with-text.png'

const Achivers = () => {
    return (
        <div className='w-full '>
            <div className='flex sm:flex-col lg:flex-row shadow-xl sm:hidden'>
                <div className='basis-[50%]'>
                    <h3 className='px-12 py-4 text-primary text-2xl sm:text-xl float-right	'>
                        Your SWATEE Bank
                    </h3>
                </div>

                <div className='basis-[50%] bg-primary'>
                    <h3 className='px-12 py-4 text-white text-2xl sm:text-xl float-left	'>
                        Our Achievers
                    </h3>
                </div>
            </div>

            {/* Image Area */}
            <div className='flex sm:flex-col lg:flex-row py-12 bg-[#f8f8f8]'>
                <div className='basis-[50%] sm:mx-auto'>
                    <div className='float-right mr-6 sm:mr-0 py-8 text-center'>
                        <h3 className='px-12 py-4 text-primary sm:text-xl md:hidden xl:hidden 2xl:hidden'>
                            Your SWATEE Bank
                        </h3>
                        <Link to='/'>
                            <img className='max-w-[400px] sm:max-w-[300px] hover:skew-y-3 hover:scale-100 duration-300' src={leaders} alt="" />
                        </Link>
                        <p className='text-[18px] font-semibold pt-4 text-[#4d4d4d]'>
                            BECOME A SWATEE MEMBER
                        </p>
                        <p className='text-[12px] pt-2'>
                            Together we can make a difference
                        </p>
                        <Link to="https://admin.swatee.in/register" target='_blank'>
                            <button className='btn'>
                                JOIN NOW
                            </button>
                        </Link>
                    </div>
                </div>

                <div className='basis-[50%] sm:mx-auto'>
                    <div className='float-left ml-6 sm:ml-0 py-8 text-center'>
                        <h3 className='px-12 py-4 text-primary sm:text-xl md:hidden xl:hidden 2xl:hidden'>
                            Our Achievers
                        </h3>
                        <Link to='/'>
                            <img className='max-w-[400px] sm:max-w-[300px] hover:skew-y-3 hover:scale-100 duration-300' src={whole} alt="" />
                        </Link>
                        <p className='text-[18px] font-semibold text-[#4d4d4d] pt-4'>
                            SUCCESS STORIES
                        </p>
                        <p className='text-[12px] pt-2'>
                            Leading Change, with Swatee lifestyle
                        </p>
                        <Link to='/about'>
                            <button className='btn'>
                                KNOW MORE
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Achivers
