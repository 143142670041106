export const services = {
  getProduct: "/get-products",
  getProductDetails: "/product-details",
  getUsers: "/users",
  descriptionForPayment: "Swatee Health SOlution Order",
  logoInPayment: "https://s3.amazonaws.com/rzp-mobile/images/rzp.jpg",
  placeOrder: "/place-customer-order",
  getTaxInfo: "/get-order-tax-discount",
  updatePayment: "/place-customer-order-payment",
  getOrder: "get-orders",
  getCoupon: "/get-coupon-detail",
  getCategory: "/product-category",
  updateUser: "/update-user",
  getOffers: "/get-offers",
  getGallery: "/get-gallery-images",
  gallery_base_URL: "https://admin.swatee.in/uploads/gallery/",
  image_base_URL: "https://admin.swatee.in/uploads/category_images/",
};

export const rozarPayKey = "rzp_live_V6SCjhhEwPUV2j";
// export const descriptionForPayment='Swatee Health SOlution Order';
// export const logoInPayment="https://s3.amazonaws.com/rzp-mobile/images/rzp.jpg";
