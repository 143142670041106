import React, { useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import TitleBar from '../title/TitleBar'

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const ForgotPass = () => {
    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/forgot', {
                email,
            });
            localStorage.setItem('token', response.data.token);
            console.log('Login Successful');
            navigate("/login")
        } catch (error) {
            // console.log('Login Failed', error.response.data.message);
            setError('Email is incorrect');
        }
    };

    //show hide password handlechange
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword => !prevShowPassword));
    };

    return (
        <div className='w-full'>

            <TitleBar pagetitle="Forgot Password" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Forgot Password</Link>
                </>}
            />

            <div className='max-w-[1200px] mx-auto px-4 py-12'>
                <h2 className='text-center'>Reset<br /> Your Password</h2>
                <p className='text-center pt-4'>Lorem ipsum dolor, sit amet consectetur adipisicing elit.<br />
                    Sit aliquid, Non distinctio vel iste.</p>
            </div>

            <div className='max-w-[1200px] flex mx-auto px-4 py-62 sm:flex-col' >
                <div className='basis-[50%] mx-auto mb-8 px-8'>
                    <form onSubmit={handleSubmit}>

                        <input type="email" id="email" className="h-[60px] border-[2px] border-[#e4ecf2] text-[#000] text-sm w-full p-6 mb-8" placeholder="Email*" required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <input id="password" className=" h-[60px] border-[2px] border-[#e4ecf2] text-[#000] text-sm w-full p-6 mb-8" placeholder="Password*" required
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <span className="relative top-[-70px] left-[94%] translate-y-negative-1/2 cursor-pointer text-primary" onClick={handleTogglePassword}>
                            {(showPassword === false) ? <AiFillEye /> : <AiFillEyeInvisible />}
                        </span>

                        {error && <p className='text-[red] text-sm mt-[-20px] pb-6'>{error}</p>}
                        <button type="submit" className="text-white text-xl bg-primary px-12 py-4">Reset Password</button>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default ForgotPass