import React from 'react'
import TitleBar from '../component/title/TitleBar'


const Services = () => {
    return (
        <div>
            <TitleBar pagetitle="Our Services" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Services</Link>
                </>}
            />
            <h1>Service Page</h1>

        </div>
    )
}

export default Services
