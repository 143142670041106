import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.jpeg'
import TitleBar from '../title/TitleBar'
import { AiOutlineHome } from 'react-icons/ai';
import axios from 'axios';
import { services } from '../../services';
import Orders from './Orders';
import Addresses from './Addresses';
import AccountDetails from './AccountDetails';
// import { faCog, faBell } from '@fortawesome/free-solid-svg-icons';

const MyAccount = () => {
  let navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);

  const [userData, setUserData] = useState({});



  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  }

  useEffect(() => {
    axios.get(services.getUsers).then(res => {
      // console.log(res.data.data);
      setUserData(res?.data?.data);
    }).catch(e => {
      console.log(e);
    })
  }, []);

  return (
    <>
      <TitleBar
        pagetitle="My Account"
        breadcrumbs={
          <>
            <Link to="/">Home</Link> &gt;
            <Link to=""> My Account</Link>
          </>
        }
      />
      <div className="container flex px-4 sm:flex-col lg:flex-row my-12">
        <div className="my-12 cursor-pointer">

          <div className='w-[300px] border-b'>
            <p
              className={`px-4 py-4 ${activeTab === 1 ? "text-primary" : "bg-white"
                }`}
              onClick={() => handleTabClick(1)}
            >
              Dashboard
            </p>
          </div>
          {/* Orders */}
          <div className='w-[300px]  border-b '>
            <p
              className={`px-4 py-4 ${activeTab === 2 ? "text-primary" : "bg-white"
                }`}
              onClick={() => handleTabClick(2)}
            >
              Orders
            </p>
          </div>
          {/* Address */}
          <div className='w-[300px]  border-b '>
            <p
              className={`px-4 py-4 ${activeTab === 3 ? "text-primary" : "bg-white"
                }`}
              onClick={() => handleTabClick(3)}
            >
              Addresses
            </p>
          </div>
          {/* Account Details */}
          <div className='w-[300px]  border-b '>
            <p
              className={`px-4 py-4 ${activeTab === 4 ? "text-primary" : "bg-white"
                }`}
              onClick={() => handleTabClick(4)}
            >
              Account Details
            </p>
          </div>
          {/* DS */}
          <div className='w-[300px]  border-b '>
            <p
              className={`px-4 py-4 ${activeTab === 5 ? "text-primary" : "bg-white"
                }`}
              onClick={() => handleTabClick(5)}
            >
              Join as DS
            </p>
          </div>
          {/* Log Out */}
          <div className='w-[300px]  border-b '>
            <p
              className={`px-4 py-4 ${activeTab === 6 ? "text-primary" : "bg-white"
                }`}
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/login")
              }}
            >
              Log Out
            </p>
          </div>
        </div>

        <div className="bg-white p-4 m-12 sm:m-0 sm:p-0">
          {activeTab === 1 && (
            <>
              <div className='order_main_container'>
                <p>
                  Hello <span className='font-bold' >{userData[0]?.first_name + ' ' + userData[0]?.last_name}</span>
                  <span className='font-bold text-primary' > (Member id: {userData[0]?.member_id})</span>
                  , (not <span className='font-bold' >{userData[0]?.first_name + ' ' + userData[0]?.last_name}</span>
                  <span className='font-bold text-primary cursor-pointer ' onClick={() => {
                    localStorage.removeItem("token");
                    navigate("/login")
                  }}> Log out</span>)

                  From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details.
                </p>
              </div>
            </>
          )}

          {activeTab === 2 && <>
            <div className='order_main_container'>
              <Orders />
            </div>
          </>}

          {activeTab === 3 && (
            <>
              <div className='order_main_container'>
                <p>
                  The following addresses will be used on the checkout page by default.
                </p>
                <div className='py-2'>
                  <Addresses />
                </div>
              </div>
            </>
          )}

          {activeTab === 4 && (
            <>
              <div className='order_main_container'>
                <AccountDetails />
              </div>
            </>
          )}
          {activeTab === 5 && (
            <>
              <div className='order_main_container'>
                <p>
                  As a direct seller, you have the freedom and flexibility to control your own business. You can set your own working hours, determine your income goals, and build your team according to your vision.
                </p>
                <p className='py-4'>
                  Swatee Health Solutions provides extensive training and support to its direct sellers. You will have access to comprehensive product knowledge, sales techniques, and marketing strategies that can empower you to excel in your role. This level of autonomy allows you to shape your entrepreneurial journey and enjoy the rewards that come with it.
                </p>
                <Link to="https://admin.swatee.in/register" target='_blank'>
                  <button className='btn'>
                    Join as DS
                  </button>
                </Link>
                <br />
                <Link to="/incomeplans">
                  <button className='btn'>
                    View Income Plans
                  </button>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default MyAccount