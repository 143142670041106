import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/logo.jpeg'
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'

const TermPolicies = () => {
    return (
        <>
            <TitleBar pagetitle="Swatee Terms and Policies" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Terms & Policies</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    <img className='w-[40%]' src={logo} alt="/Swatee Logo" />
                    {/* <h2 className='py-6 text-lg'>
                        ABOUT SWATEE PVT LTD.
                    </h2> */}
                    <p className='text-justify text-sm py-6'>
                        You can find all the Terms, Conditions and Policies related to company. For any specific query, kindly get in touch with us.
                    </p>

                    <ul className="text-primary p-4 list-disc">

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/direct-seller-terms" >Regarding Direct Sellers</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/bonus-and-dividends" >Regarding Bonus and Dividends</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/promotion-policies" >Regarding Promotion Policies</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/model-code-of-conduct" >Model Code of Conduct at Swatee Health Solutions Pvt. Ltd.</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/regarding-customer-relations" >Regarding Customer Relation Module</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/complaints-redressal-policy" >Regarding Disputes and Complaints Redressal Policy</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/return-policy" >Regarding Order Return, Cancellation and Refunds</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/shipping-policy" >Shipping Policy</Link>
                        </li>

                    </ul >
                </div>
                <div className='basis-[25%]  ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>
                    {/* <ul className="text-primary p-4">

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Regarding Direct Sellers</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Regarding Bonus and Dividends</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Regarding Promotion Policies</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Model Code of Conduct at Swatee Health Solutions Pvt. Ltd.</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Regarding Customer Relation Module</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Regarding Disputes and Complaints Redressal Policy</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Regarding Order Return, Cancellation and Refunds</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Shipping Policy</Link>
                        </li>

                    </ul > */}
                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div>
        </>
    )
}

export default TermPolicies