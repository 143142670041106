import { configureStore }  from '@reduxjs/toolkit';
import rootReducer from "../reducer";
const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if(serializedState === null) {
        return {
            cart:[]
           };
      }
      return JSON.parse(serializedState);
    } catch (e) {
      return {
     cart:[]
    };
    }
  };
  
  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch (e) {
      // Ignore write errors;
    }
  };
  
  const persistedState = loadState();

const store = configureStore({
    reducer:rootReducer,
    preloadedState:loadState()
},);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;