import React from 'react'
import { Link } from 'react-router-dom';
// import logo from '../assets/logo.jpeg'
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'
import ContactForm from '../component/forms/ContactForm';

const Redressals = () => {
    return (
        <>
            <TitleBar pagetitle="Complaints and Redressals" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Complaints and Redressals</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='py-6 text-lg'>शिकायत और निवारण प्रकोष्ठ </h2>
                    <p className='text-justify text-sm p-2 text-[#4d4d4d]'>
                        वैसे तो प्रत्येक अप-लाइन का कर्तव्य हैं कि वह अपने नेटवर्क या उपभोक्ताओं की हरेक असंतुष्टि / शिकायत को बिना विलम्ब किये प्राथमिकता के आधार पर दूर कर उन्हें संतुष्ट करें परन्तु किसी कारणवश यदि कोई सेलर (DS) ऐसा करने में असफल रहता है तो वह नीचे दी गयी ईमेल एड्रेस पर उस शिकायत / असंतुष्टि को शिकायत और निवारण प्रकोष्ठ के समक्ष रख सकता है और समाधान पा सकता हैं, यह  कंपनी के सदस्यों का ऐसा ग्रुप जिसे कम्पनी को मिलने वाली समस्त शिकायतों को सुनने और उनका निवारण करने का अधिकार प्राप्त होता हैं !
                        “शिकायत और निवारण प्रकोष्ठ” के स्थाई सदस्यों के नाम निम्नवत हैं –
                    </p>
                    <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">

                        <li className='p-1'>
                            मिस कुसुम राणा (निदेशक)
                        </li>

                        <li className='p-1'>
                            पूनम गुसाई (प्रबंधक)
                        </li>

                        <li className='p-1'>
                            शशिकुमार सैनी (सलाहकार)
                        </li>

                    </ol >

                    <p className='text-justify text-sm p-2 text-[#4d4d4d]'>
                        “शिकायत और निवारण प्रकोष्ठ” के मुख्य अधिकारी का  नाम व मोबाइल न०  निम्नवत हैं –
                    </p>
                    <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">

                        <li className='p-1'>
                            मिस्टर संजय सिलस्वाल (9997874238)
                        </li>

                    </ol >

                </div>
                <div className='basis-[25%] h-[450px] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>

                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div>


            {/* Contact Form  */}
            <div className='container px-4 py-12 flex items-center sm:flex-col lg:flex-row '>
                <div className='basis-[100%] shadow-md p-12 m-[10px]'>
                    <h3 className='py-2 border-l-2 border-primary mb-8'>
                        <span className='pl-2'>आप निम्न फॉर्म के माध्यम से भी हम तक अपनी बात पंहुचा सकते है</span>
                    </h3>

                    <div className='basis-[100%]'>

                        <ContactForm />

                    </div>
                </div>
            </div>

        </>
    )
}

export default Redressals