import React from 'react'
import { Link } from 'react-router-dom';
import { FaCheckSquare, FaCheck } from 'react-icons/fa'

import TitleBar from '../component/title/TitleBar'
import logo from '../assets/logo.jpeg'
import FaqToggle from '../component/faq/FaqToggle';

// Team Members Images
import sainiji from '../assets/swatee-team-shashi-kumar-saini-ji.jpg'
import romasainiji from '../assets/swatee-team-Rooma-Saini-ji.jpg'
import kusum from '../assets/swatee-team-kusum-rana-ji.jpg'
import poonam from '../assets/swatee-team-Poonam-Gusain-ji.jpg'
import sanjay from '../assets/swatee-team-Sanjaysilswal.jpg'
import suraj from '../assets/swatee-team-Suraj-Chauhan-ji.jpg'
// Swatee Group Logos 
import ayur from '../assets/swatee-ayur-jyoti-logo-final.png'
import yogpeeth from '../assets/swatee-yogpeeth-logo-final.png'
import yogdan from '../assets/swatee-yogdan-logo-final.png'
import swateemain from '../assets/swatee-logo-main-final.png'
// Registration Certificates of Company
import Incorporation from '../assets/pdf/CERTIFICATE-OF-INCORPORATION.pdf'
import ProtectionAct from '../assets/pdf/Consumer-Protection-Hindi.pdf'
import PAN from '../assets/pdf/PAN.pdf'
import TAN from '../assets/pdf/TAN.pdf'
import GST from '../assets/pdf/swateegst.pdf'
import FSSAI from '../assets/pdf/Fssai.pdf'
import Arogya from '../assets/pdf/arogyaformulationcertificates.pdf'


const About = () => {
    return (
        <div>
            <TitleBar pagetitle="About Swatee Group" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> About</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    <img className='w-[200px]' src={logo} alt="/Swatee Logo" />
                    {/* <h2 className='py-6 text-lg'>
                        ABOUT SWATEE HEALTH SOLUTIONS PVT LTD.
                    </h2> */}
                    <p className='text-justify text-sm pt-12'>
                        <span className='font-bold uppercase'>SWATEE Health Solutions Private Limited:</span> <br />
                        "A platform dedicated to promoting personal health and social well-being, SWATEE Health Solutions Private Limited strives to address the holistic concept of health and endeavors to educate everyone about the importance of health. It works at physical, mental, economic, social, and political levels to promote the campaign for 'Complete Health.' The aim is to ensure that individuals, communities, cities, villages, societies, and families across the country/state/city can achieve overall health and become self-reliant. Let's join hands with SWATEE Health Solutions Private Limited to help everyone attain financial independence and secure their physical health by continuously consuming health products."
                    </p>
                    <h3 className='pt-6 text-lg uppercase text-primary'>
                        SWATEE Health Solutions Private Limited (A direct selling company) Details-
                    </h3>
                    <ol className='text-[#5C727D] text-sm list-decimal mt-2 p-4 '>
                        <li className='p-1'>This company, SWATEE Health Solutions Private Limited, is a project of the SWATEE family.</li>
                        <li className='p-1'>The company's corporate office is located at H39, Shivalik Nagar, Haridwar, Uttarakhand, with the PIN code 249403.</li>
                        <li>The main branch office is situated near Gali No. 13 and 14, Chiddarwala, Punjab National Bank, on the main road between Rishikesh and Dehradun.</li>
                        <li className='p-1'>The director of the company is Miss Kusum Lata, and her permanent address is Nepali Farm, Rishikesh, Uttarakhand</li>
                        <li className='p-1'> The company is registered under the Company Act with registration number U74999UR2019OPC009795.</li>
                        <li className='p-1'>The PAN number of the company is ABCCS0684A, and the GSTIN is 05ABCCS0684A1ZS. </li>
                        <li className='p-1'>The company also holds an FSSAI License Number 12623005000005. </li>
                        <li className='p-1'>The company is also known by the abbreviation SHS. </li>
                    </ol>
                    {/* <p className='text-justify text-sm pt-6'>
                        <span className='font-bold'>SWATEE TILL DATE</span> <br />
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                        of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text,
                        and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                    </p> */}
                    <p className='text-justify text-sm pt-6'>
                        <span className='font-bold'>SWATEE MISSION & VISION</span> <br />
                        We believe that a healthy society is the foundation of a strong nation. In an effort to realize this vision, SWATEE Health Solutions Private Limited is dedicated to developing social and personal health. It is a platform that emphasizes the importance of "complete health" and strives to educate everyone about the significance of health. The company works on physical, mental, economic, social, and political levels, aiming to make everyone self-reliant.

                        It plays a role in assisting those individuals in society who, for any reason, have been unable to realize their full potential. By leveraging their unique qualities, it helps them progress. The journey of "complete health" begins with physical well-being. When our bodies are healthy, we can contribute our full potential to our work, which elevates our economic status. With a sound mind and positive thoughts, our mental health remains strong. Since society is built by our families, a happy family environment leads to a prosperous society. Our elected representatives come from this very society, so they reflect the values and thoughts prevalent in society, shaping the destiny of our region and country.

                        Therefore, SWATEE Health Solutions Private Limited strongly advocates for "complete health" because the above-mentioned principles suggest that physical and economic health are of utmost importance. Through health products and an income plan, the company is committed to empowering your physical and economic well-being. It invites all of you to contribute to this important campaign and further strengthen yourselves.
                    </p>
                    <p className='text-justify text-sm pt-6'>
                        <span className='font-bold'>IMPORTANT WORK</span> <br />
                        For physical health, SWATEE Health Solutions Private Limited provides guidance on lifestyle, thoughts, daily routine, diet, yoga, and Ayurveda. For mental health, it offers understanding of interpersonal and social relationships, advice on maintaining harmony and limited expectations between two generations, and information on counseling for solutions and behavior. To strengthen the economic status of society, it provides training in self-employment to the underprivileged and also offers opportunities for interested individuals to work with SWATEE Health Solutions Private Limited.

                        For social development, the company conducts life counseling, career counseling, and leadership training in the political field, as a healthy society is built by political leaders who possess good thinking. It empowers women, teaches them to live with dignity and respect, guides them on how to bring sweetness to troubled relationships, and promotes family health and a pleasant environment through the kitchen. The company also works as an event organizer, business promoter, and motivational speaker.
                    </p>
                </div>
                <div className='basis-[25%] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary uppercase'>
                        Registration Certificates of Company
                    </h2>
                    <ol className="text-primary list-decimal px-8 py-8">

                        <li className='p-1'>
                            <a href={Incorporation} target="_blank" className='hover:text-secondary'>Certificate of Incorporation</a>
                        </li>

                        <li className='p-1'>
                            <a href={ProtectionAct} target="_blank" className=' hover:text-secondary' to="/complaints-and-redressals" >Consumer Protection Act</a>
                        </li>

                        <li className='p-1'>
                            <a href={PAN} target="_blank" className=' hover:text-secondary' to="/" >PAN</a>
                        </li>

                        <li className='p-1'>
                            <a href={TAN} target="_blank" className=' hover:text-secondary' to="/" >TAN</a>
                        </li>

                        <li className='p-1'>
                            <a href={GST} target="_blank" className=' hover:text-secondary' to="/login" >GST</a>
                        </li>

                        <li className='p-1'>
                            <a href={FSSAI} target="_blank" className=' hover:text-secondary' to="/return-policy" >FSSAI</a>
                        </li>

                        <li className='p-1'>
                            <a href={Arogya} target="_blank" className=' hover:text-secondary' >Arogya Formulation Pvt. Ltd. (Our Manufacturing Unit)</a>
                        </li>
                    </ol >
                    <h2 className='mt-12 py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        IMPORATANT LINKS
                    </h2>
                    <ul className="text-primary px-8 py-8 list-disc">

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/terms-policies" >Swatee Terms and Policies</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/complaints-and-redressals" >Complaints and Redressals</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Advisory Committee</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/login" >Admin Login</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/return-policy" >Return Policy</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/shipping-policy" >Shipping Policy</Link>
                        </li>
                    </ul >
                    <h2 className='mt-12 py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        SWATEE GROUP
                    </h2>
                    <ul className="text-primary px-8 py-8 list-disc">

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/swatee-jyotish-peeth" >Swatee Jyotish Peeth</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Swatee Health Solutions PVT. LTD</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/swatee-yogpeeth" >Swatee YogPeeth</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/swatee-ayur-jyoti" >Swatee Ayur Jyoti</Link>
                        </li>
                    </ul >
                </div>
            </div>

            {/* SWATEE GROUP SECTION */}
            <div className='container px-4 flex sm:flex-col lg:flex-row'>
                <div className='basis-[100%] py-6 mt-8'>
                    {/* <img className='w-[200px]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='sm:text-3xl text-2xl'>
                        SWATEE GROUP
                    </h2>
                    <p className='text-justify text-sm pt-6'>
                        The importance of health is understood simply when one falls ill, and when a family devotes their time and resources to the care of the patient, they truly comprehend the meaning and significance of health. In your own life, there must have been events that acquainted you with the importance of health, and inspired by such incidents, you dedicated yourself to the cause of "Complete Health." This initiative was initiated by the SWATEE family as SWATEE Ayur Jyoti in the year 2014. Today, the three units of this family (Physical, Financial, and Social) are working actively in various fields towards the mission of complete health, having already empowered, enabled, and made thousands of individuals successful and self-reliant.
                    </p>
                </div>
            </div>
            {/* First 2 col section */}
            <div className='container px-4 flex sm:flex-col lg:flex-row'>
                <div className='basis-[50%] p-2 border border-[#000] bg-[#f2f2f2] mb-8 mr-8 sm:mr-0 sm:p-8'>
                    <div className='flex items-center sm:inline-block p-4'>
                        <img className='w-[200px] sm:w-[100%]' src={ayur} alt="/Swatee Logo" />
                        <p className='text-sm pl-4 sm:p-0 sm:mt-6 text-justify'>
                            The unit mentioned in the previous text is a company that produces Ayurvedic products based on the knowledge derived from ancient and divine sciences like Jyotish (astrology) and Ayurveda. It combines the principles of nature, Ayurvedic herbal remedies, and insights from Jyotish scriptures regarding diseases, medications, auspicious timing for formulation, and more. This unit manufactures Ayurvedic products for the family and provides astrological consultation and remedies based on Jyotish principles.
                        </p>
                    </div>
                </div>
                <div className='basis-[50%] p-2 border border-[#000] bg-[#f2f2f2] mb-8 ml-8 sm:ml-0 sm:p-8'>
                    <div className='flex items-center sm:inline-block p-4'>
                        <img className='w-[200px] sm:w-[100%]' src={yogpeeth} alt="/Swatee Logo" />
                        <p className='text-sm pl-4 sm:p-0 sm:mt-6 text-justify'>
                            Swatee Yogpeeth is a renowned yoga institute dedicated to promoting holistic wellness and spiritual growth. With a team of experienced and certified yoga instructors, the institute provides expert guidance in various yoga styles, including Hatha, Vinyasa, and Kundalini. Swatee Yogpeeth also offers specialized workshops and retreats focused on stress management, mindfulness, and self-discovery. The institute's emphasis on personalized attention, coupled with its commitment to ancient yogic traditions, makes Swatee Yogpeeth a haven for individuals seeking to rejuvenate their body, mind, and soul.
                        </p>
                    </div>
                </div>
            </div>
            {/* Second 2 col section */}
            <div className='container px-4 pb-8 flex sm:flex-col lg:flex-row'>
                <div className='basis-[50%] p-2 border border-[#000] bg-[#f2f2f2] mb-8 mr-8 sm:mr-0 sm:p-8'>
                    <div className='flex items-center sm:inline-block p-4'>
                        <img className='w-[200px]  sm:w-[100%]' src={yogdan} alt="/Swatee Logo" />
                        <p className='text-sm pl-4 sm:p-0 sm:mt-6  text-justify'>
                            This is an organization within the family that connects everyone through social service and provides support to those in need of educational, financial, or psychological assistance in society. Through this platform or institution, the SWATEE family works to nurture and establish the intellectually capable and dedicated personalities of society in the mainstream. Its main work includes opposing social evils, teaching the art of maintaining social and family relationships, providing assistance and support to people as per their needs, and teaching people to live for the betterment of society. It was established in the year 2015, and Miss Kusum Rana serves as the Secretary, while Miss Poonam Gussain serves as the Treasurer, overseeing its management. In essence, "SWATEE Yogdan Seva Sansthan" strives to empower social well-being.
                        </p>
                    </div>
                </div>
                <div className='basis-[50%] p-2 border border-[#000] bg-[#f2f2f2] mb-8 ml-8 sm:ml-0 sm:p-8'>
                    <div className='flex items-center sm:inline-block p-4'>
                        <img className='w-[200px] sm:w-[100%]' src={swateemain} alt="/Swatee Logo" />
                        <p className='text-sm pl-4 sm:p-0 sm:mt-6 text-justify'>
                            Swatee aims to nurture and support individuals in their journey towards optimal health. The program combines various elements such as physical fitness, nutrition, mental well-being, and spiritual growth to create a well-rounded and sustainable lifestyle. Through personalized consultations, fitness classes, nutritious meal plans, mindfulness practices, and meditation sessions, Swatee provides individuals with the tools and guidance they need to achieve balance and vitality in their lives. Whether one seeks to improve their physical strength, manage stress, or enhance their overall well-being, Swatee's dedicated team of experts ensures that each individual's unique needs and goals are addressed. By embracing Swatee's Way of Full Round Health, individuals can experience a transformative journey towards a healthier and more fulfilling life.
                        </p>
                    </div>
                </div>
            </div>

            {/* PEOPLE BEHIND SWATEE  */}
            {/* <div className='w-full bg-[#f2f2f2] py-12'>
                
                <div className='container px-4 flex sm:flex-col lg:flex-row'>
                    <div className='basis-[100%] py-2'>
                        
                        <h2 className='sm:text-3xl text-2xl'>
                            PEOPLE BEHIND SWATEE
                        </h2>
                    </div>
                </div>
                <div className='container px-4 flex sm:flex-col lg:flex-row'>
                    <div className='basis-[22%] mt-8 border border-primary'>
                        <img className='m-auto' src={sainiji} alt="/Swatee Logo" />
                        <p className='bg-primary p-2 text-white text-center'>
                            MR. S. K . SAINI
                        </p>
                        <p className='text-center font-normal text-sm'>
                            Chairman <span className='text-primary'>|</span> Swatee Group
                        </p>
                    </div>
                    <div className='basis-[22%] mt-8 ml-12 sm:ml-0 border border-primary'>
                        <img className='m-auto' src={romasainiji} alt="/Swatee Logo" />
                        <p className='bg-primary p-2 text-white text-center'>
                            MRS. ROOMA SAINI
                        </p>
                        <p className='text-center  font-normal  text-sm'>
                            CEO <span className='text-primary'>|</span> Swatee Health Solution
                        </p>
                    </div>
                    <div className='basis-[22%] mt-8  sm:ml-0 border border-primary'>
                        <img className='m-auto' src={kusum} alt="/Swatee Logo" />
                        <p className='bg-primary p-2 text-white text-center'>
                            MISS. KUSUM RANA
                        </p>
                        <p className='text-center  font-normal  text-sm'>
                            M.D. <span className='text-primary'>|</span> Swatee Health Solution
                        </p>
                    </div>
                    <div className='basis-[22%] mt-8 ml-12 sm:ml-0 border border-primary'>
                        <img className='m-auto' src={poonam} alt="/Swatee Logo" />
                        <p className='bg-primary p-2 text-white text-center'>
                            MISS. POONAM GUSAIN
                        </p>
                        <p className='text-center  font-normal  text-sm'>
                            G.M. <span className='text-primary'>|</span> Swatee Health Solution
                        </p>
                    </div>


                </div>
                <div className='container px-4 py-8 flex sm:flex-col lg:flex-row'>
                    <div className='basis-[22%] mt-8 border border-primary'>
                        <img className='m-auto' src={sanjay} alt="/Swatee Logo" />
                        <p className='bg-primary p-2 text-white text-center'>
                            MR. Sanjay Silswal
                        </p>
                        <p className='text-center font-normal text-sm'>
                            CRO, DS <span className='text-primary'>|</span> Swatee Health Solution
                        </p>
                    </div>
                    <div className='basis-[22%] mt-8 ml-12 sm:ml-0 border border-primary'>
                        <img className='m-auto' src={suraj} alt="/Swatee Logo" />
                        <p className='bg-primary p-2 text-white text-center'>
                            MR. Suraj Chauhan
                        </p>
                        <p className='text-center  font-normal  text-sm'>
                            DS <span className='text-primary'>|</span> Swatee Health Solution
                        </p>
                    </div>

                </div>
            </div> */}

            {/* FaQ Section */}
            {/* SWATEE GROUP SECTION */}
            <div className='container px-4 flex sm:flex-col lg:flex-row'>
                <div className='basis-[100%] py-6 mt-8'>
                    {/* <img className='w-[200px]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='sm:text-3xl text-2xl'>
                        FREQUENTLY ASKED QUESTIONS
                    </h2>
                </div>
            </div>

            {/* FOQ TOGGLE */}
            <div className='container pb-12 px-4'>
                <FaqToggle
                    question="How much can one earn with SHS in a month ?"
                    answer={
                        <>It's totally depends upon you and your dedication, Here is no limit, for details you can see <a className='text-primary font-bold text-xxl' href='https://swatee.in/incomeplans'>INCOME PLAN</a>.</>}
                />
                <FaqToggle
                    question="Is this a full time job ?"
                    answer="You are free from any time limit, It's your own business,more time more income less time less income, you can start your business from any place like your home, your working place, from anywhere."
                />
                <FaqToggle
                    question="Are SHS’s products completely ayurvedic ?"
                    answer="Yes, the company's health products are 100% ayurvedic and formulated with astrology."
                />
                <FaqToggle
                    question="Do I need any educational and professional degree or experience to join SHS ?"
                    answer="There is no need for any type of educational and professional degree or experience. You should be passionate about your dream business with SHS."
                />
                <FaqToggle
                    question="What will be the form of joining ?"
                    answer={<>
                        You join with SHS as DS (Direct Seller) as per contract made. Now you should create your business group and to know how it works, visit our website’s <a className='text-primary font-bold text-xxl' href='https://swatee.in/incomeplans'>Income Plan</a> page.
                    </>}
                />
                <FaqToggle
                    question="Is there any investment needed ?"
                    answer=" There is no required investment. Use SHS’s products yourself and say to your acquaintance (knowing one), your business will start without any investment."
                />


            </div>
        </div >
    )
}

export default About
