export const cartDataAdd=(payload)=>{
    return {
        type:"CART_ADD",
        payload:payload
    };

}
export const cartDataRemove=(payload)=>{
    return {
        type:"CART_REMOVE",
        payload:payload
    };

}
export const globalLoader=(payload)=>{
    return {
        type:"LOADER",
        payload:payload
    };

}

export const cartCalculation=(payload)=>{
    return {
        type:"CART_CALULATION",
        payload:payload
    };

}

export const cartCouponData=(payload)=>{
    return {
        type:"CART_COUPON",
        payload:payload
    };

}