
import RoutesList from './RoutesList';
import { BrowserRouter as Router } from "react-router-dom";
import Header from './component/global/Header';
import Footer from './component/global/Footer';
import axios from 'axios';
import { ShoppingCartContext } from "./component/context/shoppingCartContext";
import { useEffect, useState, } from 'react';
import store from './store/index';
import { Provider } from 'react-redux';


export default function App() {

  axios.defaults.baseURL = 'https://admin.swatee.in/api';
  axios.defaults.headers.post['Contacnt-Type'] = 'application/json';
  axios.defaults.headers.post['Accept'] = 'application/json';

  //axios.defaults.withCredentials = true;
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  })



  // const getcartURL = "/get-cart-data";
  // const fetchProductsCount = () => {
  //   axios.get(getcartURL).then(response => {
  //     if (response.data.status !== 'error') {
  //       setCart(response.data.data.length);
  //     } else {
  //       setCart(0);
  //     }
  //   })
  //     .catch(error => {
  //       console.log(error);
  //     });;
  //   window.scrollTo(0, 0);
  // };

  // const changeCartCount = () => {
  //   fetchProductsCount();
  // };

  // useEffect(() => {
  //   fetchProductsCount();

  // }, [cart])
  // useEffect(() => {
  //   mainRef?.current?.scroll(0, 0);
  // }, [])

  return (
    < >
      <Provider store={store}>
        <Router>
          <RoutesList />
        </Router>
      </Provider>
    </>
  )
}