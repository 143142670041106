import React from 'react'
import { Link } from 'react-router-dom';
// import logo from '../assets/logo.jpeg'
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'
import FaqToggle from '../component/faq/FaqToggle';

const ShippingPolicy = () => {
    return (
        <>
            <TitleBar pagetitle="Shipping Policy" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Shipping Policy</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='py-6 text-lg'>Shipping Charges and Timeline</h2>
                    {/* <p className='text-justify text-sm p-2 text-[#4d4d4d]'>
                किसी को आपसे शिकायत होना एक सामान्य प्रकिया प्रक्रिया हैं इसे अन्यथा ना लें, शिकायतकर्ता की शिकायत अनिवार्य रूप से सुनें और उसका समाधान या निवारण करने में अपना शतप्रतिशत सहयोग दें उसके लिए कंपनी द्वारा कुछ तरीके / नियम बताएँ हैं जो निम्न प्रकार से हैं –
            </p> */}
                    <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">

                        <li className='p-1'>
                        Indian Shipping Charges- free shipping in Uttarakhand and Chargeable in remaining parts of India.
                        </li>

                        <li className='p-1'>
                            International Shipping Charges (Other Than India) :- Flat Rs: 3000/-
                        </li>

                        <li className='p-1'>
                            Minimum shipping timeline :- 3 days
                        </li>

                        <li className='p-1'>
                            Maximum shipping timeline :- 7 days
                        </li>

                    </ol >

                </div>
                <div className='basis-[25%] h-[450px] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>

                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div>

            <div className='container px-4 flex sm:flex-col lg:flex-row'>
                <div className='basis-[100%] py-6 mt-8'>
                    {/* <img className='w-[200px]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='sm:text-3xl text-2xl'>
                        Other Shipping FAQs
                    </h2>
                </div>
            </div>
            {/* FOQ TOGGLE */}
            <div className='container pb-12 px-4'>
                <FaqToggle
                    question="Who are the shipping partners?"
                    answer="We ship all orders through various courier partners. The available shipping partners are Fedex, DTDC, ARAMEX, Indian Speed Post Services."
                />
                <FaqToggle
                    question="Where does we deliver in India?"
                    answer="We delivers to most locations within India. However, for logistical reasons, we are unable to deliver to a few locations right now. Kindly confirm if you have any doubt your location."
                />
                <FaqToggle
                    question="Where does we deliver outside India?"
                    answer="We delivers to most locations around the world covered by above mentioned courier partners.  Kindly make sure your location is serviceable else your order will be refunded/cancelled by us."
                />
                <FaqToggle
                    question="How much time will it take for the delivery of the order?"
                    answer="We make our best efforts to ship each item in your order within 2 working days of the order. However in some cases, we may take longer up to 2-3 working days to ship.
 
                    With every order, you will receive an email containing the details of the order placed by you. Once we ship the item(s), you will receive another email containing the shipping details."
                />
                <FaqToggle
                    question="How will the delivery be done?"
                    answer="We process all deliveries through reputed couriers. Once your order is shipped you will be provided with an Airway Bill Number and the name of the courier partner that you can use to track your delivery on the logistic company’s website."
                />
                <FaqToggle
                    question="What happens if I am not available to receive my parcel?"
                    answer="Should you not be present when our courier attempts to deliver your parcel, our courier partner makes attempts to reach you via a phone call or SMS. Please ensure that you take calls from our courier partner to facilitate delivery of your package. A second attempt will automatically be made within 24 hours. After the second attempt, our courier will hold on to the parcel for 3 days awaiting further instructions from the recipient before returning the parcel to us.
 
                    Once the parcel has been shipped back to us by the courier partner, we will automatically cancel that order. The payment made by you towards the cancelled order will be refunded as per refund policy via the original mode of payment."
                />
                <FaqToggle
                    question="What should I do if I receive a damaged parcel?"
                    answer="If when you receive your parcel you notice that it is damaged or broken, you should refuse to accept it. All parcels are guaranteed to be in perfect condition when they are dispatched from our end. Kindly contact us in case you refuse to accept the parcel."
                />

            </div>

        </>
    )
}

export default ShippingPolicy