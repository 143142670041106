import React from 'react'
import { Link } from "react-router-dom";

import { BiCurrentLocation, BiPhoneCall, BiMailSend } from 'react-icons/bi';
import gplay from '../../assets/get-it-on-google-play-logo.png'


const Footer = () => {
    return (
        <div className='bg-primary py-20'>
            <div className='container px-4 flex sm:flex-wrap'>

                {/* Contact Details */}
                <div className='basis-1/4 sm:basis-full sm:py-8 text-sm mr-10 '>
                    {/* <h4 className='text-[#fff] pb-4'>Corporate Address</h4>
                    <p className='text-[#fff] py-2'>
                        <BiCurrentLocation className='text-2xl inline-block' />
                        <span className='space-x-2.5'> Address: H39, Shivalik Nagar, Haridwar.
                            Pin:249403 Uttrakhand</span> </p> */}
                    <h4 className='text-[#fff] py-4'>Office Address</h4>
                    <p className='text-[#fff] py-2'>
                        <BiCurrentLocation className='text-2xl inline-block' />
                        <span className='space-x-2.5'> Gali no. 13 & 14, Main Road, Chiddarwala, Rishikesh, DehradunPin:249204 Uttrakhand</span> </p>
                    <p className='text-[#fff] py-2 hover:text-secondary'>
                        <BiPhoneCall className='text-2xl inline-block' />
                        <a href="tel:8218112501">(+91) 70555-20015</a></p>
                    <p className='text-[#fff] py-2 hover:text-secondary'>
                        <BiPhoneCall className='text-2xl inline-block' />
                        <a href="tel:8218112501">(+91) 82181-12501</a></p>
                    <p className='text-[#fff] py-2 hover:text-secondary'>
                        <BiMailSend className='text-2xl inline-block' />
                        <a href="mailto:swateehealthsolution@gmail.com">swateehealthsolution@gmail.com</a></p>
                </div>
                {/* Quick Links */}
                <div className='basis-1/4 mr-10  sm:basis-full sm:py-8'>
                    <h4 className='text-[#fff] pb-4'>Quick Links</h4>
                    <ul className="text-[#f2f2f2]  text-sm">
                        <Link to="/" >
                            <li className='p-1 hover:text-secondary'>Home</li>
                        </Link>
                        <Link to="/about">
                            <li className='p-1 hover:text-secondary'>About Us </li>
                        </Link>
                        <Link to="/shop">
                            <li className='p-1 hover:text-secondary'>Products</li>
                        </Link>
                        <Link to="/incomeplans" >
                            <li className='p-1 hover:text-secondary'>Income Plans</li>
                        </Link>
                        <Link to="/swatee-jyotish-peeth" >
                            <li className='p-1 hover:text-secondary'>Swatee Jyotish Peeth</li>
                        </Link>
                        <Link to="/swatee-yogpeeth" >
                            <li className='p-1 hover:text-secondary'>Swatee Yog Peeth</li>
                        </Link>
                        <Link to="/swatee-ayur-jyoti" >
                            <li className='p-1 hover:text-secondary'>Swatee Ayur Jyoti</li>
                        </Link>
                        <Link to="/gallery">
                            <li className='p-1 hover:text-secondary'>Gallery</li>
                        </Link>
                        <Link to="/contact">
                            <li className='p-1 hover:text-secondary'>Contact Us</li>
                        </Link>
                    </ul>
                </div>
                {/* Terms & Conditions */}
                <div className='basis-1/4 mr-20  sm:basis-full sm:py-8'>
                    <h4 className='text-[#fff] pb-4'>Terms & Conditions</h4>
                    <ul className="text-[#f2f2f2] text-sm">

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/direct-seller-terms" >Regarding Direct Sellers</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/bonus-and-dividends" >Regarding Bonus And Dividends</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/promotion-policies" >Regarding Promotion Policies</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/model-code-of-conduct" >Model Code Of Conduct At Swatee Health Solutions Pvt. Ltd.</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/regarding-customer-relations" >Regarding Customer Relation Module</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/complaints-redressal-policy" >Regarding Disputes And Complaints Redressal Policy</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/return-policy" >Regarding Order Return, Cancellation And Refunds</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/shipping-policy" >Shipping Policy</Link>
                        </li>

                    </ul >
                </div>
                {/* Health Articles */}
                <div className='basis-1/4 sm:basis-full sm:py-8'>
                    <h4 className='text-[#fff] pb-4'>Health Articles</h4>
                    <ul className="text-[#f2f2f2]  text-sm">
                        <Link to="/human-digestive-system">
                            <li className='p-1 hover:text-secondary'>Human Digestive System</li>
                        </Link>
                        <Link to="/womens-health" >
                            <li className='p-1 hover:text-secondary'>Women's Health</li>
                        </Link>
                        <Link to="/ways-to-keep-lever-healthy" >
                            <li className='p-1 hover:text-secondary'>Ways To keep Liver Healthy</li>
                        </Link>

                    </ul>
                    <h4 className='text-[#fff] py-4'>Download Our App</h4>
                    <Link to='https://play.google.com/store/apps/details?id=com.kgwebtech.shs' target="_blank">
                        <img className='max-w-[150px]' src={gplay} alt="" />
                    </Link>
                </div>
            </div>
        </div >


    )
}

export default Footer
