import React from 'react'

import Slider from '../component/slider/Slider'
import FeaturedProduct from '../component/home/FeaturedProduct'
import Achivers from '../component/home/Achivers'
import LoginForms from '../component/home/LoginForms'
import CategoryHome from '../component/home/CategoryHome'



const Home = () => {
    return (
        <div>

            <Slider />
            <Achivers />
            <FeaturedProduct />
            <CategoryHome />
            {/* <LoginForms /> */}

        </div>
    )
}

export default Home
